/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { v4 as uuid } from "uuid";
import { authService } from "./services/msAuthService";



const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

Office.onReady(() => {
  if (Office.context.host === Office.HostType.Word || Office.context.host === Office.HostType.PowerPoint) {
    setupUserInformations();
  }
  root?.render(<App />);
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}

const setupUserInformations = async () => {
  // await handleSignIn();
  if (!localStorage.getItem("userId")) {
    localStorage.setItem("userId", uuid());
  }
  if (!localStorage.getItem("cartId")) {
    localStorage.setItem("cartId", uuid());
  }
};




async function handleGetEmail() {
    try {
        const userInfo = await authService.getUserInfo();
        console.log("User email:", userInfo.mail || userInfo.userPrincipalName);
    } catch (error) {
        console.error("Error getting user info:", error);
    }
}

// Or if you need to explicitly sign in first:
async function handleSignIn() {
    try {
        await authService.signIn();
        const userInfo = await authService.getUserInfo();
        console.log("User email:", userInfo.mail || userInfo.userPrincipalName);
    } catch (error) {
        console.error("Error signing in:", error);
    }
}