import * as msal from "@azure/msal-browser";
import axios from "axios";

class AuthService {
    private msalInstance: msal.PublicClientApplication;
    private initialized: boolean = false;

    constructor() {
        this.msalInstance = new msal.PublicClientApplication({
            auth: {
                clientId: "c43a1c4c-cf60-49a7-b31d-69e2914c260d",
                authority: "https://login.microsoftonline.com/common",
                redirectUri: "https://localhost:3000/callback.html",
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
        });
    }

    async initialize() {
        if (!this.initialized) {
            await this.msalInstance.initialize();
            this.initialized = true;
        }
    }

    async signIn() {
        try {
            await this.initialize();
            const response = await this.msalInstance.loginPopup({
                scopes: ["User.Read"]
            });
            return response;
        } catch (error) {
            console.error("Sign in error:", error);
            throw error;
        }
    }

    async getUserInfo() {
        try {
            await this.initialize();
            
            const account = this.msalInstance.getAllAccounts()[0];
            if (!account) {
                throw new Error("No active account");
            }

            const response = await this.msalInstance.acquireTokenSilent({
                scopes: ["User.Read"],
                account: account
            });

            const graphResponse = await axios.get("https://graph.microsoft.com/v1.0/me", {
                headers: {
                    Authorization: `Bearer ${response.accessToken}`
                }
            });

            // Since we're using axios, .data is already the parsed JSON
            return graphResponse.data;
            
        } catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                return this.signIn().then(() => this.getUserInfo());
            }
            console.error("Get user info error:", error);
            throw error;
        }
    }
}

// Export a single instance
export const authService = new AuthService();