import * as React from "react";
import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import GeneratePage from "../pages/generate";
import ScenesPage from "../pages/scenes";
import 'bootstrap/dist/css/bootstrap.min.css';
import PostCheckoutPage from "../pages/postCheckout";
import OrdersPage from "../pages/orders";
import CartPage from "../pages/cart";
import CheckoutFailPage from "../pages/checkoutFail";
import CheckoutSuccessPage from "../pages/checkoutSuccess";
import SelectBackgroundVideoPage from "../pages/selectBackgroundVideoDialog";
import ShowVideoPage from "../pages/showVideoDialog";
import ShowVideoInfoPage from "../pages/showVideoInfo";
import DownloadVideoPage from "../pages/downloadVideoDialog";
import ErrorPage from "../pages/ErrorPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GeneratePage />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/scenes" element={<ScenesPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout-fail" element={<CheckoutFailPage />} />
        <Route path="/checkout-success" element={<CheckoutSuccessPage />} />
        <Route path="/post-checkout" element={<PostCheckoutPage/>} />
        <Route path="/select-video" element={<SelectBackgroundVideoPage/>} />
        <Route path="/show-video" element={<ShowVideoPage/>} />
        <Route path="/show-video-info" element={<ShowVideoInfoPage/>} />
        <Route path="/download-video" element={<DownloadVideoPage/>} />
        <Route path="/500" element={<ErrorPage/>} />
      </Routes>
    </Router>
  );
};

export default App;
