import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../styles/dropdown.css";

const CustomSelect: React.FC<{
  data: any;
  value: string;
  options: string[];
  placeholder: string;
  onChange: (data: any) => void;
  hasAudio: boolean;
}> = ({ value, options, placeholder, onChange, hasAudio, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(data);
  const [playingAudio, setPlayingAudio] = useState<HTMLAudioElement | null>(null);

  const dropdownRef = useRef();

  const handleOutsideClick = (e) => {
    if (e.target.contains(dropdownRef.current)) {
      setIsOpen(false);
    }
  };

  useEffect(()=>{

    if(!isOpen){
      stopAudio();
      const updatedData = [...selectedData];

      updatedData.forEach((d) => {
        if(d.isPlaying){
          d.isPlaying = false;
        }
      });
      
      setSelectedData(updatedData);
    }

  },[isOpen]);

  const playAudio = (url: string) => {
    if (playingAudio) playingAudio.pause();
    const audio = new Audio(url);
    audio.play();
    console.log('Reload');
    audio.addEventListener("ended", () => {
      const updatedData = [...selectedData];

      updatedData.forEach((d) => {
        d.isPlaying = false;
      });

      setSelectedData(updatedData);
    });
    setPlayingAudio(audio);
  };

  const stopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
      setPlayingAudio(null);
    }
  };

  const audioPlay = (index: number) => {
    const updatedData = [...selectedData];
    updatedData.forEach((d, secondIndex) => {
      if (secondIndex !== index) {
        d.isPlaying = false;
      }
    });
    updatedData[index].isPlaying = !updatedData[index].isPlaying;
    if (updatedData[index].isPlaying) {
      playAudio(updatedData[index].audio_url);
    } else {
      stopAudio();
    }
    setSelectedData(updatedData);
  };

  const handleOnChange = (opt) => {
    onChange(opt);
    setIsOpen(false);
    stopAudio();
    const updatedData = [...selectedData];

    updatedData.forEach((d) => {
      d.isPlaying = false;
    });

    setSelectedData(updatedData);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownBtn onClick={() => setIsOpen(!isOpen)}>
        {value || placeholder}
        {!isOpen ? <DownIcon /> : <UpIcon />}
      </DropdownBtn>
      {isOpen && (
        <DropdownList>
          {options.map((opt, index) => (
            <DropdownItem key={index} className={opt === value && "active"}>
              {hasAudio && (
                <div>
                  <button className="play-stop" onClick={() => audioPlay(index)}>
                    <img
                      src={selectedData[index].isPlaying ? "assets/pause-svg-icon.svg" : "assets/play-icon-2.png"}
                    ></img>
                  </button>
                </div>
              )}

              <div
                style={{ width: "100%", height: "100%", fontFamily: "Avenir Book", marginLeft: "2px" }}
                onClick={() => {
                  handleOnChange(opt);
                }}
              >
                {opt}
              </div>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default CustomSelect;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DropdownBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Avenir Book" !important;
  min-width: 244px;
  height: 41px;
  background-color: #fff;
  color: black;
  border: 1px solid rgba(24, 24, 24, 0.7);
  border-radius: 4px;
  font-size: var(--input-font-size);
  padding: 0 15px;
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const DropdownList = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 999;
  font-family: "Avenir Book" !important;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  margin-top: 4px;
  border: 1px solid #eee;
  position: absolute;
  width: 100%;
  margin-top: 48px;
  max-height: 200px;
  overflow-y: auto;
`;

const DropdownItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 32px;
  background: #fff;
  border: none;
  color: black;
  font-size: var(--input-font-size);
  font-family: "Avenir Book" !important;
  text-align: left;
  cursor: pointer;
  padding: 0 15px;
  &.active,
  &:hover {
    background-color: #eee;
  }
`;

const DownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const UpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
    </svg>
  );
};
