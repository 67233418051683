import React, { useEffect, useState } from "react";
import VideoJS from "../components/VideoJS";
import videojs from "video.js";
import "../styles/showVideoInfo.css";
import { BASE_APP_URL } from "../constants/apiUrls";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const ShowVideoInfoPage: React.FC = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const playerRef = React.useRef(null);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [msg, setMsg] = useState('');


  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: selectedOrder ? selectedOrder.video_url : undefined,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  
  const handleShare = (order: any) => {

    const input = document.createElement("input");
    input.type = "text";
    input.value = order.video_url;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    input.remove();

    handleShowAlert('We have succesfully copied your Video URL to clipboard', 'success');

    // if (navigator.canShare(shareData)) {
    //   navigator
    //     .share(shareData)
    //     .then(() => {
    //       handleShowAlert("Succesfully shared", "success");
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       handleShowAlert("An error ocured while sharing:" + e, "danger");
    //     });
    // } else {
    //   handleShowAlert("Your browser does not support share functionality!", "danger");
    // }
  };

  const handleShowAlert = (msg: string, variant: string) => {
    setMsg(msg);
    setShowAlert(true);
    setAlertVariant(variant);

    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const handleEditVideo = (videoSuccessId) => {
    localStorage.setItem("successVideoId", videoSuccessId);
    Office.context.ui.messageParent(JSON.stringify({
        status: "ok",
        data: videoSuccessId
    }));
  };

  const handleDownload = async (videoUrl: string, filename: string) => {
    console.log(videoUrl);
    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    setSelectedOrder(JSON.parse(localStorage.getItem("selectedOrder")));
  }, []);

  return (
    <>
      {selectedOrder && (
        <div className="col-md-12 col-sm-12 col-xs-12 info-container">
          <div className="row first-row">
            <div className="border col-md-8 col-sm-12 video-info-container ml-2">
              {selectedOrder && <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />}
            </div>
            <div className="col-md-3 col-sm-12 border option-container">
              <ul className="option-content">
                <li onClick={() => handleEditVideo(selectedOrder.enterprise_video_id)}>
                  <img className="dropdown-item-image" src="assets/tt-edit-icon.png" alt="" />
                  Edit Video
                </li>
                <li onClick={() => handleDownload(selectedOrder.video_url, `video-${selectedOrder.name}.mp4`)}>
                  <img className="dropdown-item-image" src="assets/tt-download-icon.png" alt="" />
                  Download
                </li>
                <li onClick={() => handleDownload(selectedOrder.thumbnail_url, `video-${selectedOrder.name}-thumbnail.png`)}>
                  <img className="dropdown-item-image" src="assets/thumbnail.png" alt="" />
                  Download Thumbnail
                </li>
                {/* <li>
                  <img className="dropdown-item-image" src="assets/gif.png" alt="" />
                  Download GIF
                </li> */}
                <li onClick={() => handleShare(selectedOrder)}>
                  <img className="dropdown-item-image" src="assets/copy-clipboard.png" alt="" />
                  Copy Shareable Link
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 border table-container">
                <div className="table-responsive info-table">
                    <table className="table info-modal-table home-table-style">
                        <thead>
                            <tr>
                                <th>Vertical</th>
                                <th>Aspect Ratio</th>
                                <th>Generated At</th>
                                <th>Render Time</th>
                                <th>Search Tags</th>
                            </tr>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>Landscape</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </thead>

                    </table>
                </div>
            </div>
          </div> */}
          
        <Alert key={alertVariant} show={showAlert} variant={alertVariant}>
          {msg}
        </Alert>
        </div>
      )}
    </>
  );
};

export default ShowVideoInfoPage;
