import React, { useEffect, useState } from "react";
import "../styles/postCheckout.css";
import '../styles/global.css';
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";


const PostCheckoutPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <img id="videomagic-header-icon" src="/assets/videomagic-logo.png" width="165" height="auto" alt="" />
            <div className="post-checkout-container">
                <span className="post-checkout-title"> You will receive an email when your order is ready!</span>
                <div className="action-container">
                    <button className="action-button" onClick={() => navigate("/orders")}>Okay</button>
                </div>
            </div>
            <Footer></Footer>
        </>

    );

}

export default PostCheckoutPage;
