import React, { useEffect, useState } from "react";
import "../styles/selectBackgroundVideo.css";
import { getBackgroundVideos } from "../services/getBackgroundVideos";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import VideoJS from "../components/VideoJS";
import videojs from 'video.js';

const enterpriseVideoCaptions: any[] = [
  {
    id: "default",
    label: "Default",
    is_default: true,
    preview_url:
      "https://devstudio.videomagic.ai/Enterprise_automation/videos/865be530-232b-11ef-8ed1-0573e35fe2e0/test11output.mp4",
  },
  {
    id: "yellow_karoke",
    label: "Yellow Karoke",
    is_default: false,
    preview_url:
      "https://devstudio.videomagic.ai/Enterprise_automation/videos/865be530-232b-11ef-8ed1-0573e35fe2e0/test3output.mp4",
  },
  {
    id: "violet_karoke",
    label: "Violet Karoke",
    is_default: false,
    preview_url:
      "https://devstudio.videomagic.ai/Enterprise_automation/videos/91e8bd9w2-ad72-446f-bf9f-75a232d99192/a29d0bce-483a-4972-9330-28debf0b35cf.mp4",
  },
  {
    id: "blue_karoke",
    label: "Blue Karoke",
    is_default: false,
    preview_url:
      "https://devstudio.videomagic.ai/Enterprise_automation/videos/d5095202-aa0a-47ca-a47a-1ccad2f5b81f/568defc0-6b46-465c-b365-0c87f38c3aec.mp4",
  },
];

const SelectBackgroundVideoPage: React.FC = () => {
  const [backgroundVideos, setBackgroundVideos] = useState<any[]>([]);
  const [bgSelection, setBgSelection] = useState<boolean>(true);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const playerRef = React.useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: videoSrc,
      type: 'video/mp4'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  useEffect(() => {
    const href = window.location.href;
    if (href.includes("bgSelection")) {
      const fetchBackgroundVideos = async () => {
        const data = await getBackgroundVideos(0, 50);
        setBackgroundVideos(data.data);
      };
      fetchBackgroundVideos();
    } else {
      setBgSelection(false);
    }
  }, []);

  const handleThumbnailClick = async (index, close) => {
    setSelectedVideoIndex(index);
    await handleVideoSelect(index, close);
    setDialogOpen(true);
  }

  const handleVideoSelect = async (selectedVideoIndex, close) => {
    console.log(selectedVideoIndex);
    if (bgSelection) {
      localStorage.setItem("selectedBackgroundVideo", backgroundVideos[selectedVideoIndex].asset_url.landscape);
      setVideoSrc(backgroundVideos[selectedVideoIndex].asset_url.landscape);
      setTimeout(()=>{
        console.log("Wait for update localstorage");
      },500);
    } else {
      console.log("Caption selection.");
      localStorage.setItem("selectedCaption", enterpriseVideoCaptions[selectedVideoIndex].preview_url);
      setVideoSrc(enterpriseVideoCaptions[selectedVideoIndex].preview_url);
      setTimeout(()=>{
        console.log("Wait for update localstorage");
      },500);
    }

    if(close){
     Office.context.ui.messageParent(
      JSON.stringify({
        type: "dialogClosed",
      })
    );
    }

 
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className="bg-dialog">
      <div className={`video-container ${backgroundVideos?.length > 4 ? 'allow-scroll' : ''}`}>
        {bgSelection &&
          backgroundVideos.map((video, index) => (
            <div key={video.segment_id} className="video-item">
              <Form.Check
                className="change-check"
                checked={selectedVideoIndex === index}
                type="checkbox"
                color="rgb(255, 73, 64)"
                onChange={() => {
                  setSelectedVideoIndex(index);
                }}
              />
              <div className="video-content">
                <div className="video-wrapper">
                  <video className="showing-video" onClick={()=> {handleThumbnailClick(index, false)}}>
                    
                    <source src={video.asset_url.landscape} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="video-label">Videos-{index + 1}</p>
              </div>
            </div>
          ))}
        {!bgSelection &&
          enterpriseVideoCaptions.map((video,index) => (
            
            <div key={video.id} className="video-item">
               <Form.Check
                className="change-check"
                checked={selectedVideoIndex === index}
                type="checkbox"
                color="rgb(255, 73, 64)"
                onChange={() => {
                  setSelectedVideoIndex(index);
                }}
              />
              <div className="video-content">
                <div className="video-wrapper">
                  <video className="showing-video" onClick={()=> {handleThumbnailClick(index, false)}}>
                    <source src={video.preview_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="video-label">Videos-{index + 1}</p>
              </div>
            </div>
          ))}
      </div>

      <div className="action-container video-selection-action">
        <button className="change-save-button" onClick={() => handleVideoSelect(selectedVideoIndex, true)}>
          Select
        </button>
      </div>

      <Modal size="lg" show={dialogOpen} onHide={handleCloseDialog} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        </Modal.Body>
        <Modal.Footer>
          <div className="action-container">
            <button className="change-save-button" onClick={() => handleVideoSelect(selectedVideoIndex, true)}>
              Select
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectBackgroundVideoPage;
