import axios from 'axios';
import { BASE_API_URL } from '../constants/apiUrls';

const API_URL = `${BASE_API_URL}/enterprise-dynamic-video-automation`;

export const generateScenesRequest = async (data: any) => {

    console.log(data.videoCaption);
    const requestData = {
        mode: "content-extraction",
        video_creation_mode: "create_edit",
        input_url: data.inputUrl,
        video_title: data.videoName,
        background_video: {
            "url": localStorage.getItem('selectedBackgroundVideo')
        },
        voice: {
            "voiceName": data.voiceName,
            "voiceLanguageCode": "en-US",
            "speed": 1
        },
        music: {
            "asset_url": data.selectedMusic
        },
        language: "English",
        aspect_ratio: "landscape",
        source_platform: "add_ins",
        input_type: "document",
        video_duration: {
            "durationMin": data.duration[0],
            "durationMax": data.duration[1]
        },
        video_caption: data.videoCaption
    }
    console.log("Actual data", requestData);

    try {
        const response = await axios.post(API_URL, requestData, {
            headers: {
                'accept': 'application/json, text/plain, */*',
                'accept-language': 'en-US,en-GB;q=0.9,en;q=0.8,tr-TR;q=0.7,tr;q=0.6',
                'authorization': `Bearer ${localStorage.getItem('vmPluginAccessToken')}`,
                'content-type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching scenes:', error);
        throw error;
    }
};

