import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/orders.css";
import "../styles/global.css";
import Footer from "../components/footer";
import { v4 as uuid } from "uuid";
import { BASE_API_URL, BASE_APP_URL } from "../constants/apiUrls";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { Tooltip } from 'react-tooltip'
import FileSaver, { saveAs } from 'file-saver';



const OrdersPage: React.FC = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");

  const fetchOrders = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`${BASE_API_URL}/add-ins-orders/${userId}`);
      setOrders(response.data.orders);
      localStorage.setItem("orders", JSON.stringify(response.data.orders));
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch orders");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    var currentOrders = JSON.parse(localStorage.getItem("orders"));
    console.log(currentOrders !== orders);
    console.log(currentOrders != orders);
    const intervalId = setInterval(() => {
      fetchOrders();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const handleEditVideo = (videoSuccessId) => {
    localStorage.setItem("successVideoId", videoSuccessId);
    localStorage.setItem("isEditMode", "edit");
    Office.context.ui.displayDialogAsync(
      `${BASE_APP_URL}/taskpane.html#/scenes?videoSuccessId=${videoSuccessId}`,
      { height: 100, width: 70, displayInIframe: true },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const dialog = asyncResult.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (message: any) => {
            navigate("/orders");
            console.log(JSON.parse(message.message));
            fetchOrders();
            dialog.close();
          });
          dialog.addEventHandler(Office.EventType.DialogEventReceived, () => {
            dialog.close();
            console.log("Dialog closed by the user");
          });
        } else {
          const dialog = asyncResult.value;
          dialog.close();
          console.error("Failed to open dialog: ", asyncResult.error.message);
        }
      }
    );
  };

  const handleDownload = async (videoUrl: string, filename: string) => {
    const platform = Office.context.platform;
    const isMac = platform === Office.PlatformType.Mac;
    if (isMac) {
      Office.context.ui.openBrowserWindow(`${BASE_APP_URL}/download.html?videoUrl=${videoUrl}&filename=${filename}`)
    }
    else {
      const response = await axios.get(videoUrl, {
        responseType: 'blob',
      });
      saveAs(response.data, filename);
    }
  };

  const handleShare = (order: any) => {
    const input = document.createElement("input");
    input.type = "text";
    input.value = order.video_url;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    input.remove();

    handleShowAlert("We have succesfully copied your Video URL to clipboard", "success");
  };

  const handleInfo = (order: any) => {
    localStorage.setItem("selectedOrder", JSON.stringify(order));
    Office.context.ui.displayDialogAsync(
      `${BASE_APP_URL}/taskpane.html#/show-video-info`,
      { height: 80, width: 60, displayInIframe: true },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const dialog = asyncResult.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (msg: any) => {
            console.log(msg);
            const ms = JSON.parse(msg.message);
            console.log(ms.data);
            dialog.close();
            setTimeout(() => {
              handleEditVideo(ms.data);
            }, 500);
          });
        } else {
          console.error("Failed to open dialog: ", asyncResult.error.message);
        }
      }
    );
  };

  const handleShowAlert = (msg: string, variant: string) => {
    setError(msg);
    setShowAlert(true);
    setAlertVariant(variant);

    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    !loading && (
      <>
        {/* <img
          id="videomagic-header-icon"
          src="/assets/videomagic-logo.png"
          width="165"
          height="auto"
          alt="Videomagic logo"
        /> */}

        <Alert key={alertVariant} show={showAlert} variant={alertVariant} className="custom-alert">
          <span className="orders-name">{error}</span>
        </Alert>
        {orders.length > 0 && (
          <div className="orders-container">
            <span className="my-orders-label">My Orders</span>

            <div className="orders-body" key={uuid()}>
              <table>
                <thead>
                  <tr>
                    <th className="table-header">Video</th>
                    <th className="table-header">Name</th>
                    <th className="table-header">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr>
                      <td>
                        {order.status === "video_failed" || order.status === "video_complete" ? (
                          <img
                            className="order-thumbnail"
                            src={
                              order.status === "video_failed" ? "assets/empty-thumbnail-png.png" : order.thumbnail_url
                            }
                            onClick={() => {
                              if (order.status === "video_complete") {
                                localStorage.setItem("orders-video-url", order.video_url);
                                if(Office.context.platform === Office.PlatformType.Mac){
                                  Office.context.ui.openBrowserWindow(order.video_url);
                                }
                                else{
                                  var url = `${BASE_APP_URL}/taskpane.html#/show-video?orders=true`;
                                  Office.context.ui.displayDialogAsync(
                                    url,
                                    { height: 80, width: 60, displayInIframe: true },
                                    (asyncResult) => {
                                      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                        const dialog = asyncResult.value;
                                        dialog.addEventHandler(Office.EventType.DialogMessageReceived, () => {
                                          dialog.close();
                                        });
                                      } else {
                                        console.error("Failed to open dialog: ", asyncResult.error.message);
                                      }
                                    }
                                  );
                                }
                    
                              }
                            }}
                            alt=""
                          />
                        ) : 
                        
                        order.status === "request_created" ? 
                        <span className="orders-name">Queued</span> 
                        : 
                       (
                        <span className="orders-name">Processing</span> 
                      )
                      }
                      </td>
                      <td className="orders-name">{order.name}</td>
                      <td>
                        {order.status === "video_failed" && (
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src="assets/warning-icon.webp" className="warning-icon" alt="Info"
                              data-tooltip-id="video-failed-tooltip-id"
                              data-tooltip-content={order.error_info}
                              data-tooltip-place="left"
                            />
                            <Tooltip id="video-failed-tooltip-id" 
                              place="top"
                              style={{ maxWidth: '300px' }}
                            />
                          </div>
                        )}
                        {order.status === "video_complete" && (
                          <Dropdown>
                            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                              <img src="assets/options-icon.png" className="options-icon" alt="Options" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleEditVideo(order.enterprise_video_id)}>
                                <img className="dropdown-item-image" src="assets/tt-edit-icon.png" alt="" />
                                Edit
                              </Dropdown.Item>
                              {/* <Dropdown.Item onClick={() => handleInfo(order)}>
                                <img className="dropdown-item-image" src="assets/tt-info-icon.png" alt="" />
                                Info
                              </Dropdown.Item> */}
                              <Dropdown.Item onClick={() => handleShare(order)}>
                                <img className="dropdown-item-image" src="assets/share-icon.png" alt="" />
                                Share
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleDownload(order.video_url, `${order.name}.mp4`)}>
                                <img className="dropdown-item-image" src="assets/tt-download-icon.png" alt="" />
                                Download
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        {order.status !== "video_failed" && order.status !== "video_complete" && (
                          <div className="loading-spinner"></div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {orders.length <= 0 && (
          <div className="no-length-container">
            <span>You don't have any orders. How about creating one?</span>
            <button
              className="footer-btn sf-generate-btn"
              onClick={() => {
                navigate("/");
              }}
            >
              Generate Video
            </button>
          </div>
        )}

        <Footer />
      </>
    )
  );
};

export default OrdersPage;
