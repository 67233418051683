import axios from 'axios';
import { BASE_API_CLIENT_ID, BASE_API_CLIENT_SECRET, BASE_API_URL } from '../constants/apiUrls';

const TOKEN_URL = `${BASE_API_URL}/token`;
const TOKEN_EXPIRY_DURATION = 24 * 60 * 60 * 1000; 

export async function fetchToken() {
  const response = await axios.post(TOKEN_URL, {
    client_id: BASE_API_CLIENT_ID,
    client_secret: BASE_API_CLIENT_SECRET,
  });
  console.log(response);
  const token  = response.data.access_token;

  const expiryTime = new Date().getTime() + TOKEN_EXPIRY_DURATION;
  return { token, expiryTime };
}

export function isTokenExpired() {
  const expiryTime = localStorage.getItem('vmPluginAccessTokenExpiryTime');
  if (!expiryTime) return true;
  return new Date().getTime() > parseInt(expiryTime);
}

export async function getValidToken() {
  if (isTokenExpired()) {
    const { token } = await fetchToken();
    return token;
  }
  return localStorage.getItem('token');
}
