import React, { useEffect, useState } from "react";
import "../styles/postCheckout.css";
import '../styles/global.css';
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";


const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="post-checkout-container">
                <span className="post-checkout-title"> An unexpected error occured, please try again!</span>
                <div className="action-container">
                    <button className="action-button" onClick={() => navigate("/")}>Okay</button>
                </div>
            </div>
            <Footer></Footer>
        </>

    );

}

export default ErrorPage;
