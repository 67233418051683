export const voices = [
    { 'label': 'Chris (Male)', 'audio_url': 'https://devstudio.videomagic.ai/sample-audio/en-US-News-N.mp3', 'voiceName': 'en-US-News-N', 'voiceLanguageCode': 'en-US', 'tags': [] , 'isPlaying':false },
    { 'label': 'Ethan (Male)', 'audio_url': 'https://devstudio.videomagic.ai/sample-audio/en-US-Polyglot-1.mp3', 'voiceName': 'en-US-Polyglot-1', 'voiceLanguageCode': 'en-US', 'tags': [] , 'isPlaying':false },
    { 'label': 'Jake (Male)', 'audio_url': 'https://devstudio.videomagic.ai/sample-audio/en-US-Standard-J.mp3', 'voiceName': 'en-US-Standard-J', 'voiceLanguageCode': 'en-US', 'tags': [] , 'isPlaying':false },
    { 'label': 'Emily (Female)', 'audio_url': 'https://devstudio.videomagic.ai/sample-audio/en-US-Standard-G.mp3', 'voiceName': 'en-US-Standard-G', 'voiceLanguageCode': 'en-US', 'tags': [], 'isPlaying':false },
    { 'label': 'Rachael (Female)', 'audio_url': 'https://devstudio.videomagic.ai/sample-audio/en-US-Wavenet-G.mp3', 'voiceName': 'en-US-Wavenet-G', 'voiceLanguageCode': 'en-US', 'tags': [], 'isPlaying':false },
    { 'label': 'Grace (Female)', 'audio_url': 'https://devstudio.videomagic.ai/sample-audio/en-US-Wavenet-H.mp3', 'voiceName': 'en-US-Wavenet-H', 'voiceLanguageCode': 'en-US', 'tags': [], 'isPlaying':false },
  ];
  
  export const musics = [
    { 'label': 'Cadance', 'audio_url': 'https://devstudio.videomagic.ai/music/cadance.mp3', 'tags': [], 'isPlaying':false },
    { 'label': 'Celebrate', 'audio_url': 'https://devstudio.videomagic.ai/music/celebrate-loop.mp3', 'tags': [], 'isPlaying':false },
    { 'label': 'Crescendo', 'audio_url': 'https://devstudio.videomagic.ai/music/crescendo-zeta.mp3', 'tags': [], 'isPlaying':false },
    { 'label': 'Futuristi', 'audio_url': 'https://devstudio.videomagic.ai/music/futuristi-human-ai-version-2.mp3', 'tags': [], 'isPlaying':false },
    { 'label': 'Grand', 'audio_url': 'https://devstudio.videomagic.ai/music/grand-MRC.mp3', 'tags': [], 'isPlaying':false },
    { 'label': 'Meadow', 'audio_url': 'https://devstudio.videomagic.ai/music/meadow-jop.mp3', 'tags': [], 'isPlaying':false },
    { 'label': 'Spring', 'audio_url': 'https://devstudio.videomagic.ai/music/spring-beauty.mp3', 'tags': [], 'isPlaying':false }
  ];
  
  export const languages = ["English"];