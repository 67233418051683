import React, { useEffect, useState } from 'react';
import '../styles/cart.css';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer';
import { BASE_APP_URL } from '../constants/apiUrls';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { BASE_API_URL } from '../constants/apiUrls';

const CartPage: React.FC = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const fetchCartItems = async () => {
    try {
      const cartId = localStorage.getItem('cartId')
      const response = await fetch(`${BASE_API_URL}/add-ins-cart/${cartId}`);
      const data = await response.json();
      console.log(data);
      if (data.status === 'success') {
        const items = []
        data.cart.forEach(element => {
          if (element.paid === 0) {
            items.push(element)
          }
        });
        setCartItems(items);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const createStripeSession = async () => {
    try {
      const cartId = localStorage.getItem('cartId');
      const response = await fetch(`${BASE_API_URL}/add-ins-buy-videos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cart_id: cartId,
          success_url: `${BASE_APP_URL}/taskpane.html#/checkout-success`,
          cancel_url: `${BASE_APP_URL}/taskpane.html#/checkout-fail`,
        }),
      });
      const data = await response.json();
      if (data && data.url) {
        Office.context.ui.displayDialogAsync(data.url, { height: 90, width: 50 },
          (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              const dialog = asyncResult.value;
              dialog.addEventHandler(Office.EventType.DialogMessageReceived, (message: any) => {
                dialog.close();
                console.log(message.message);
                const ms = JSON.parse(message.message);
                if (ms.message === "success") {
                  localStorage.setItem('cartId', uuid());
                  if (localStorage.getItem('isLoggedIn')) {
                    navigate('/post-checkout');
                  }
                  else {
                    navigate('/post-checkout')
                  }
                }
                else {
                  console.log('Error!!')
                }
              });
            } else {
              console.error("Failed to open dialog: ", asyncResult.error.message);
            }
          });
      } else {
        console.error('Error creating Stripe session:', data);
      }
    } catch (error) {
      console.error('Error creating Stripe session:', error);
    }
  };

  const removeItem = async (id) => {
    // const currentItems = cartItems.filter((c) => c.id !== id);
    await axios.delete(`${BASE_API_URL}/add-ins-cart/${id}?orientation=landscape`);
    fetchCartItems();
    // setCartItems(currentItems);
  }

  return (
    <>
      <img id="videomagic-header-icon" src="/assets/videomagic-logo.png" width="165" height="auto" alt="" />
      {!isLoading && <div>
        {cartItems.length > 0 &&
          <div className="cart-content">
            <div className="cart-container">
              <div className="title-container">
              </div>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '72%' }}>
                        <span className="title">Cart</span></th>
                      <th style={{textAlign:'end'}} colSpan={2}>
                        <span id="totalVideoNo">{cartItems.length === 1 ? cartItems.length + " Video" : cartItems.length + " Videos"} </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="item-container">
                    {cartItems.map((item, index) => (
                      <tr key={item.id}>
                        <td style={{ width: "72%" }}>
                          <div className="img-container">
                            <img
                              src={item.sample_video_info && item.sample_video_info.length > 0 ? item.sample_video_info[0].thumbnail : 'assets/cart-thumbnail.png'}
                              width={200}
                              height={75}
                              alt={item.template_name}
                            />
                          </div>
                        </td>
                        <td style={{width:'23%'}}>
                          <div className="video-ratio">
                            $4.99
                          </div>
                        </td>
                        <td>
                          <button className="btn delete-btn" data-index={index} onClick={() => { removeItem(item.id) }}>
                            <img src="../assets/delete.png" alt="Delete" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="checkout-container" style={{ marginTop: "5px" }}>
              <span className='order-summary'>ORDER SUMMARY</span>
              <div className="description-value">
                <span>Subtotal:</span>
                <span id="subTotalValue">${(4.99 * cartItems.length).toFixed(2)}</span>
              </div>

              <hr />
              <div className="description-value">
                <span>Order Total:</span>
                <span id="totalValue">${(4.99 * cartItems.length).toFixed(2)}</span>
              </div>
              <button className="btn btn-primary checkout-btn" onClick={createStripeSession}>Checkout</button>
              <span className='fail-note'>If your orders fails, your card will not be charged.</span>
            </div>
            <Footer />
          </div>
        }
        {cartItems.length <= 0 &&
          <div className='empty-cart-container'>
            <div className='empty-content'>
              <h5 >Your cart is empty!</h5>
              <button
                className="gohome-btn"
                onClick={() => { navigate('/') }}
              >
                Generate Video
              </button>
            </div>
            <Footer></Footer>
          </div>
        }

      </div>


      }
      {
        isLoading && <div>
          <div className="content">
          </div>
        </div>
      }
    </>
  );
};

export default CartPage;
