import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CheckoutFailPage: React.FC = () => {
    useEffect(()=>{
        Office.context.ui.messageParent(
            JSON.stringify(
            {
                message:"failed"
            }
        ));
    })

    return(
        <div></div>
    );

}

export default CheckoutFailPage;
