import * as filestack from 'filestack-js';
import { v4 as uuidv4 } from 'uuid';

// Initialize Filestack client
const client = filestack.init('AbiIdt2ueRKuOIUvReyfaz');

const generateFileNameWithUUID = (): string => {
    if (Office.context.host === Office.HostType.PowerPoint) {
        return `${uuidv4()}.pptx`;
    } else {
        return `${uuidv4()}.pdf`;
    }
};

const retrieveDocumentAsBuffer = async (file: any): Promise<Uint8Array> => {
    const buffer = new Uint8Array(file.size);
    for (let sliceIndex = 0; sliceIndex < file.sliceCount; sliceIndex++) {
        const sliceResult = await getFileSlice(file, sliceIndex);
        if (sliceResult && sliceResult.status === Office.AsyncResultStatus.Succeeded) {
            const slice = sliceResult.value;
            buffer.set(new Uint8Array(slice.data), sliceIndex * 62000);
        } else {
            throw new Error('Error in slice retrieval');
        }
    }
    return buffer;
};

const getFileSlice = (file: any, sliceIndex: number): Promise<any> => {
    return new Promise((resolve, reject) => {
        file.getSliceAsync(sliceIndex, (result: any) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                resolve(result);
            } else {
                reject(result.error || new Error('Failed to get slice'));
            }
        });
    });
};

const bytesToFile = (buffer: Uint8Array, fileName: string, mimeType: string): File => {
    const blob = new Blob([buffer], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
};

export async function getPdfUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileType = Office.context.host === Office.HostType.PowerPoint ? 
            Office.FileType.Compressed : 
            Office.FileType.Pdf;

        Office.context.document.getFileAsync(fileType, async (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                const file = result.value;
                try {
                    const fileName = generateFileNameWithUUID();
                    const mimeType = Office.context.host === Office.HostType.PowerPoint ? 
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' :
                        'application/pdf';

                    const buffer = await retrieveDocumentAsBuffer(file);
                    const fileObject = bytesToFile(buffer, fileName, mimeType);

                    const uploadOptions: filestack.UploadOptions = {
                        onProgress: (evt) => {
                            console.log(`Upload progress: ${evt.totalPercent}%`);
                        }
                    };

                    const uploadResult = await client.upload(fileObject, uploadOptions);

                    if (uploadResult?.url) {
                        const downloadUrl = `${uploadResult.url}?filename=${fileName}`;
                        localStorage.setItem('pdfFileUrl', downloadUrl);
                        resolve(downloadUrl);
                    } else {
                        throw new Error('Upload failed: No URL returned');
                    }

                    file.closeAsync();
                } catch (error) {
                    console.error('Error uploading to Filestack:', error);
                    file.closeAsync();
                    reject(error);
                }
            } else {
                reject(result.error);
            }
        });
    });
}