export function isDocumentEmpty() {
    return new Promise((resolve, reject) => {
        Word.run(function (context) {
          const paragraphs = context.document.body.paragraphs;
          paragraphs.load("items");
  
          const inlinePictures = context.document.body.inlinePictures;
          inlinePictures.load("items");
  
          const tables = context.document.body.tables;
          tables.load("items");
  
          const contentControls = context.document.body.contentControls;
          contentControls.load("items");
  
          const charts = context.document.body.parentContentControlOrNullObject.contentControls;
          charts.load("items");
  
          return context.sync().then(() => {
            const hasText = paragraphs.items.some(paragraph => paragraph.text.trim().length > 0);
            console.log('text', hasText);
            const hasInlineImages = inlinePictures.items.length > 0;
            console.log('Images',hasInlineImages)
            const hasTables = tables.items.length > 0;
            console.log('tables',hasTables);
            const hasContentControls = contentControls.items.length > 0;
            console.log('contentControls', hasContentControls);
            if (hasText || hasInlineImages || hasTables || hasContentControls) {
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }).catch((error) => {
          console.error("Error during synchronization: " + error);
          reject(true);
        });
    });
  }