import React, { useEffect, useState } from "react";
import VideoJS from "../components/VideoJS";
import videojs from "video.js";

const ShowVideoPage: React.FC = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src:  videoUrl,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  useEffect(() => {
    const href = window.location.href;
    if (href.includes("selectedBackgroundUrl")) {
      setVideoUrl(localStorage.getItem("selectedBackgroundVideo"));
    } else if (href.includes("orders")) {
      setVideoUrl(localStorage.getItem("orders-video-url"));
    } else {
      setVideoUrl(localStorage.getItem("selectedCaption"));
    }
  }, []);

  return (
    <div className="show-video-dialog-container"
    >
      {videoUrl.length > 0 && <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />}
    </div>
  );
};

export default ShowVideoPage;
