import React from "react";
import '../styles/progressBar.css';

const ProgressBar = (props) => {
  const {
    isLoading = props.percent === "loading",
    percent,
    size = "small",
    showInfo = false,
  } = props;

  const icons = [
    "assets/analyzing-input.png",
    "assets/extracting-data.png",
    "assets/generating-script.png",
    "assets/looking-for-stock-content.png",
    "assets/searching-for-icons.png",
    "assets/generating-ai-tools.png",
    "assets/images-videos.png",
    "assets/voice-over.png",
    "assets/synchronizing.png",
    "assets/brand-values.png",
    "assets/done.png"
  ];

  const labels = [
    "Analyzing input...",
    "Extracting data...",
    "Generating a script...",
    "Looking for appropriate stock content...",
    "Searching for icons...",
    "Accessing generative AI tools...",
    "Generating images and videos...",
    "Generating a voice over...",
    "Synchronizing script, content and voice over...",
    "Making sure brand guidelines are met...",
    "Done!"
  ];

  const getIconIndex = (percent) => {
    if (percent === 100) return icons.length - 1;
    return Math.floor(percent / (100 / (icons.length - 1)));
  };

  const iconIndex = getIconIndex(percent);

  return (
    <div className="progress-container">
      <img src={icons[iconIndex]} alt="progress icon" className="progress-icon" />
      <div className="progress">
        <div className="progress-bar" style={{ width: percent + "%" }}></div>
      </div>
      <span className="progress-label">{labels[iconIndex]}</span>
      {isLoading === false && showInfo && (
        <span className="progress-info">{percent}%</span>
      )}
    </div>
  );
};

export default ProgressBar;
