import React, { useEffect } from "react";

const DownloadVideoPage: React.FC = () => {

    useEffect(() => {

        const downloadVideo = async () => {

            const href = window.location.href;
            const queryString = href.split('download-video?')[1];
            console.log(queryString);
            const params = new URLSearchParams(queryString);
            const videoUrl = params.get('downloadUrl');
            const filename = params.get('filename');
            console.log(videoUrl);
            console.log(filename);
            if (videoUrl) {
                try {
                    const response = await fetch(videoUrl);
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                    Office.context.ui.messageParent('Download complete');
                } catch (error) {
                    console.error('Download failed:', error);
                    Office.context.ui.messageParent('Download failed');
                }
            } else {
                console.error('Video URL not provided');
                Office.context.ui.messageParent('Video URL not provided');
            }
        };

        downloadVideo();
    }, []);

    return (

        <div className="loading-container">
            <div>Downloading video...</div>
        </div>

    );
}

export default DownloadVideoPage;
