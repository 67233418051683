import * as filestack from 'filestack-js';
import { v4 as uuidv4 } from 'uuid';

// Initialize Filestack client
const client = filestack.init('AbiIdt2ueRKuOIUvReyfaz');

// Type definitions
interface OfficeResult {
    status: 'succeeded' | 'failed';
    value?: any;
    error?: any;
}

interface OfficeSlice {
    data: ArrayBuffer;
    size: number;
}

interface OfficeFile {
    size: number;
    sliceCount: number;
    getSliceAsync: (sliceIndex: number, callback: (result: OfficeResult) => void) => void;
    closeAsync: () => void;
}

const MIME_TYPES = {
    PDF: 'application/pdf',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
} as const;

const generateFileNameWithUUID = (): string => {
    const uuid = uuidv4();
    if (Office.context.host === Office.HostType.PowerPoint) {
        return `${uuid}.pptx`;
    }
    return `${uuid}.pdf`;
};

const getMimeType = (): string => {
    if (Office.context.host === Office.HostType.PowerPoint) {
        return MIME_TYPES.PPTX;
    }
    return MIME_TYPES.PDF;
};

const getFileSlice = (file: OfficeFile, sliceIndex: number): Promise<OfficeSlice> => {
    return new Promise((resolve, reject) => {
        file.getSliceAsync(sliceIndex, (result: OfficeResult) => {
            if (result.status === 'succeeded' && result.value) {
                resolve(result.value as OfficeSlice);
            } else {
                reject(result.error || new Error('Failed to get slice'));
            }
        });
    });
};

const retrieveDocumentAsBuffer = async (file: OfficeFile): Promise<Uint8Array> => {
    const buffer = new Uint8Array(file.size);
    
    for (let sliceIndex = 0; sliceIndex < file.sliceCount; sliceIndex++) {
        try {
            const slice = await getFileSlice(file, sliceIndex);
            buffer.set(new Uint8Array(slice.data), sliceIndex * 62000);
        } catch (error) {
            throw new Error(`Error retrieving slice ${sliceIndex}: ${error}`);
        }
    }
    
    return buffer;
};

export async function getPdfUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileType = Office.context.host === Office.HostType.PowerPoint 
            ? Office.FileType.Compressed 
            : Office.FileType.Pdf;

        Office.context.document.getFileAsync(fileType, async (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded && result.value) {
                const file:any = result.value;
                
                try {
                    // Get the document as a buffer
                    const buffer = await retrieveDocumentAsBuffer(file);
                    
                    // Get MIME type and filename
                    const mimeType = getMimeType();
                    const fileName = generateFileNameWithUUID();
                    
                    // Convert buffer to Blob with correct MIME type
                    const blob = new Blob([buffer], { type: mimeType });
                    
                    // Create a File object from the Blob
                    const fileObject = new File([blob], fileName, { 
                        type: mimeType
                    });

                    // Upload to Filestack with correct options type
                    const uploadOptions: filestack.UploadOptions = {
                        onProgress: (evt) => {
                            console.log(`Upload progress: ${evt.totalPercent}%`);
                        }
                    };

                    const uploadResult = await client.upload(fileObject, uploadOptions);

                    if (uploadResult?.url) {
                        // Add the filename parameter to the URL
                        const downloadUrl = `${uploadResult.url}?filename=${fileName}`;
                        console.log('File uploaded successfully to Filestack:', downloadUrl);
                        localStorage.setItem('pdfFileUrl', downloadUrl);
                        resolve(downloadUrl);
                    } else {
                        reject(new Error('Upload failed: No URL returned'));
                    }
                } catch (error) {
                    console.error('Error uploading to Filestack:', error);
                    reject(error);
                } finally {
                    file.closeAsync();
                }
            } else {
                reject(result.error || new Error('Failed to get file from Office'));
            }
        });
    });
}

// Usage example
export async function uploadDocument() {
    try {
        const fileUrl = await getPdfUrl();
        console.log('Document uploaded successfully:', fileUrl);
        return fileUrl;
    } catch (error) {
        console.error('Failed to upload document:', error);
        throw error;
    }
}