import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const checkCurrentUrl = () => {
    const href = window.location.href;

    if(href.includes('orders')){
      return true;
    }
    else{
      return false;
    }
  };

  return (
    <div className="cart-footer">
      <div
         className={`footer-item ${checkCurrentUrl() ? 'selected-url' : ''}`}
        onClick={() => {
          navigate("/orders");
        }}
      >
        <img
          src={checkCurrentUrl() ? "assets/orders-logo.png" : "assets/orders-80x80.png"}
          alt="ORders"
          className="footer-icon"
        />
        <span>Orders</span>
      </div>
      <div className="footer-divider" />
      <div
        className={`footer-item ${!checkCurrentUrl() ? 'selected-url' : ''}`}
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          src={!checkCurrentUrl() ? "assets/generate-logo.png" : "assets/generate-80x80.png"}
          alt="Cart"
          className="footer-icon"
        />
        <span>Generate</span>
      </div>
    </div>
  );
};

export default Footer;
