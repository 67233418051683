import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { v4 as uuid } from 'uuid';
import { getPdfUrl } from "./helpers/doc2pdf_helper";


const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

Office.onReady(() => {
  if(Office.context.host === Office.HostType.Word || Office.context.host === Office.HostType.PowerPoint){
    setupUserInformations();
  }
  root?.render(
      <App />
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}


const setupUserInformations = async ()=>{  
  if(!localStorage.getItem('userId')){
    localStorage.setItem('userId', uuid());
  }
  if(!localStorage.getItem('cartId')){
    localStorage.setItem('cartId', uuid());
  }
  

}