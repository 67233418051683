import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Range } from "react-range";
import Alert from "react-bootstrap/Alert";

import "../styles/generate.css";
import "../styles/global.css";
import CustomSelect from "../components/dropdown";
import Footer from "../components/footer";
import ProgressBar from "../components/progressBar";

import { languages, musics, voices } from "../constants/musics";
import { enterpriseVideoCaptions } from "../constants/captions";
import { BASE_API_URL, BASE_APP_URL } from "../constants/apiUrls";
import { getBackgroundVideos } from "../services/getBackgroundVideos";
import { generateScenesRequest } from "../services/generateScenes";
import { getPdfUrl } from "../helpers/doc2pdf_helper";
import { fetchToken, isTokenExpired } from "../services/generateToken";
import { isDocumentEmpty } from "../helpers/is_word_doc_empty";

export const STEP = 1;
export const MIN = 30;
export const MAX = 150;
export const MIN_DIFFERENCE = 10;

export const calculateGradient = (values, min, max) => {
  const [leftValue, rightValue] = values;
  const leftPercentage = ((leftValue - min) / (max - min)) * 100;
  const rightPercentage = ((rightValue - min) / (max - min)) * 100;
  return `linear-gradient(to right, #D8D8D8 ${leftPercentage}%, #00B7EA ${leftPercentage}%, #FF4940 ${rightPercentage}%, #D8D8D8 ${rightPercentage}%)`;
};

export const formatDuration = (value) => {
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

const GeneratePage: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    selectedMusic: musics[0],
    selectedVoice: voices[0],
    selectedLanguage: languages[0],
    selectedBackgroundVideo: null,
    backgroundVideos: [],
    captionEnabled: false,
    duration: [50, 60],
    isAnalyzing: false,
    progressBarPercent: 0,
    videoName: "",
    selectedCaption: enterpriseVideoCaptions[0].preview_url,
    videoNameError: null,
    videoSuccessId: null,
    isScenesLoaded: false,
    showLanguage: false,
    showVoice: false,
    showMusic: false,
    showBackgroundVideo: false,
    showVideoCaption: false,
    showVideoDuration: false,
    bottomContainerArray: [],
    contentExtractionFailedMessage: null,
    alertVariant: "danger",
    showAlert: false,
  });

  const dialogRef = useRef<Office.Dialog | null>(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await handleTokenRefresh();
        getDocumentName();
        await setupBackgroundVideos();
        initializeLocalStorage();
      } catch (error) {
        console.error("Error initializing app:", error);
        navigate("/error-500");
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
  
    if (!state.isScenesLoaded && state.isAnalyzing) {
      interval = setInterval(checkSceneStatus, 5000);
    }
  
    // Always return a cleanup function
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [state.isScenesLoaded, state.isAnalyzing]);

  const handleTokenRefresh = async () => {
    try {
      if (localStorage.getItem('vmPluginAccessTokenExpiryTime')) {
        if (isTokenExpired()) {
          const { token, expiryTime } = await fetchToken();
          localStorage.setItem('vmPluginAccessToken', token);
          localStorage.setItem('vmPluginAccessTokenExpiryTime', expiryTime.toString());
        }
      } else {
        const { token, expiryTime } = await fetchToken();
        localStorage.setItem('vmPluginAccessToken', token);
        localStorage.setItem('vmPluginAccessTokenExpiryTime', expiryTime.toString());
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      navigate("/error-500");
    }
  };

  const getDocumentName = () => {
    Office.context.document.getFilePropertiesAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const docUrl = asyncResult.value.url;
        const docName = decodeURIComponent(docUrl.substring(docUrl.lastIndexOf("/") + 1));
        localStorage.setItem("documentName", docName);
      } else {
        console.error("Error getting document name: ", asyncResult.error.message);
      }
    });
  };

  const setupBackgroundVideos = async () => {
    try {
      const bgVideos = await getBackgroundVideos(0, 50);
      setState(prev => ({ ...prev, backgroundVideos: bgVideos.data }));
      if (bgVideos.data.length > 0) {
        const selectedVideo = localStorage.getItem("selectedBackgroundVideo") || bgVideos.data[0].asset_url.landscape;
        setState(prev => ({ ...prev, selectedBackgroundVideo: selectedVideo }));
        localStorage.setItem("selectedBackgroundVideo", selectedVideo);
      }
    } catch (error) {
      console.error("Error setting up background videos:", error);
      navigate("/error-500");
    }
  };

  const initializeLocalStorage = () => {
    localStorage.setItem("durationMin", "50");
    localStorage.setItem("durationMax", "60");
    localStorage.setItem("selectedCaption", enterpriseVideoCaptions[0].preview_url);
  };

  const checkSceneStatus = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/enterprise-dynamic-video-automation/${localStorage.getItem('successVideoId')}`
      );
      if (response.data.status === "completed") {
        handleSceneCompletion();
      } else if (response.data.status === "content_extraction_failed") {
        handleShowAlert("Content extraction failed.", "danger");
      } else {
        setState(prev => ({ ...prev, progressBarPercent: Math.min(prev.progressBarPercent + 5, 95) }));
      }
    } catch (error) {
      console.error("Error checking scene status:", error);
      handleShowAlert("Something went wrong. Please reach out the videomagic help team.", "danger");
    }
  };

  const handleSceneCompletion = () => {
    setState(prev => ({ 
      ...prev, 
      isSceneLoaded: true, 
      progressBarPercent: 100 
    }));
    localStorage.setItem("isEditMode", "new-video");
    openSceneDialog();
  };


  const openSceneDialog = () => {
    try {
      Office.context.ui.displayDialogAsync(
        `${BASE_APP_URL}/taskpane.html#/scenes?videoSuccessId=${localStorage.getItem('successVideoId')}`,
        { height: 90, width: 70, displayInIframe: Office.context.platform === Office.PlatformType.Mac ? false : true },
        handleDialogCallback
      );
    } catch (error) {
      console.error("Error opening scene dialog:", error);
      Office.context.ui.openBrowserWindow(`${BASE_APP_URL}/taskpane.html#/scenes?videoSuccessId=${localStorage.getItem('successVideoId')}`);
      handleShowAlert("Error opening dialog. Opened in browser instead.", "warning");
    }
  };

  const handleDialogCallback = (asyncResult: Office.AsyncResult<Office.Dialog>) => {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      dialogRef.current = asyncResult.value;
      dialogRef.current.addEventHandler(Office.EventType.DialogMessageReceived, handleDialogMessage);
      dialogRef.current.addEventHandler(Office.EventType.DialogEventReceived, handleDialogClosed);
    } else {
      console.error("Failed to open dialog: ", asyncResult.error.message);
      handleShowAlert(`Something went wrong. Please reach out the videomagic help team.${asyncResult.error.message}`, "danger");
    }
  };

  const handleDialogMessage = (arg: any) => {
    try {
      const message = JSON.parse(arg.message);
      console.log("Received message from dialog:", message);
      localStorage.removeItem('successVideoId');
      navigate("/orders");
    } catch (error) {
      console.error("Error processing dialog message:", error);
    } finally {
      closeDialog();
    }
  };

  const handleDialogClosed = (arg: any) => {
    console.log("Dialog closed event received:", arg.type);
    localStorage.removeItem('successVideoId');
    setState(prev => ({ 
      ...prev, 
      videoName: null, 
      isAnalyzing: false, 
      isSceneLoaded: false, 
      progressBarPercent: 0 
    }));
    dialogRef.current = null;
  };

  const closeDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
      dialogRef.current = null;
    }
  };
  const handleShowAlert = (msg: string, variant: string) => {
    setState(prev => ({
      ...prev,
      contentExtractionFailedMessage: msg,
      showAlert: true,
      alertVariant: variant
    }));

    setTimeout(() => {
      setState(prev => ({ 
        ...prev, 
        showAlert: false, 
        isAnalyzing: false, 
        progressBarPercent: 0, 
        isSceneLoaded: false 
      }));
    }, 2500);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(prev => ({ ...prev, videoName: event.target.value, videoNameError: null }));
  };

  const onMusicChange = (elem: any) => {
    const sm = musics.find((x) => x.label === elem);
    setState(prev => ({ ...prev, selectedMusic: sm }));
  };

  const onVoiceChange = (elem: any) => {
    const sv = voices.find((x) => x.label === elem);
    setState(prev => ({ ...prev, selectedVoice: sv }));
  };

  const onLanguageChange = (elem: any) => {
    setState(prev => ({ ...prev, selectedLanguage: elem }));
  };

  const onBackgroundVideoChange = () => {
    Office.context.ui.displayDialogAsync(
      `${BASE_APP_URL}/taskpane.html#/select-video?bgSelection=true`,
      { height: 80, width: 60, displayInIframe: true },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const dialog = asyncResult.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, () => {
            dialog.close();
            setState(prev => ({ ...prev, selectedBackgroundVideo: localStorage.getItem("selectedBackgroundVideo") }));
          });
        } else {
          console.error("Failed to open dialog: ", asyncResult.error.message);
        }
      }
    );
  };

  const onCaptionSelectionChange = () => {
    Office.context.ui.displayDialogAsync(
      `${BASE_APP_URL}/taskpane.html#/select-video?caption=true`,
      { height: 80, width: 60, displayInIframe: true },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const dialog = asyncResult.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, () => {
            dialog.close();
            setState(prev => ({ ...prev, selectedCaption: localStorage.getItem("selectedCaption") }));
          });
        } else {
          console.error("Failed to open dialog: ", asyncResult.error.message);
        }
      }
    );
  };

  const onCaptionChange = () => {
    setState(prev => ({ ...prev, captionEnabled: !prev.captionEnabled }));
  };

  const handleSliderChange = (values) => {
    const [newMin, newMax] = values;
    if (newMax - newMin >= MIN_DIFFERENCE) {
      setState(prev => ({ ...prev, duration: values }));
    } else if (newMin >= state.duration[1] - MIN_DIFFERENCE) {
      setState(prev => ({ ...prev, duration: [prev.duration[1] - MIN_DIFFERENCE, prev.duration[1]] }));
    } else if (newMax <= state.duration[0] + MIN_DIFFERENCE) {
      setState(prev => ({ ...prev, duration: [prev.duration[0], prev.duration[0] + MIN_DIFFERENCE] }));
    }

    localStorage.setItem("durationMin", values[0].toString());
    localStorage.setItem("durationMax", values[1].toString());
  };

  const handleBottomContainerArray = (value: string) => {
    setState(prev => ({
      ...prev,
      bottomContainerArray: [...prev.bottomContainerArray, value]
    }));
  };

  const showVideoDialog = (isBg: boolean) => {
    const url = isBg
      ? `${BASE_APP_URL}/taskpane.html#/show-video?selectedBackgroundUrl=true`
      : `${BASE_APP_URL}/taskpane.html#/show-video?caption=true`;

    Office.context.ui.displayDialogAsync(url, { height: 80, width: 60, displayInIframe: true }, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, () => {
          dialog.close();
        });
      } else {
        console.error("Failed to open dialog: ", asyncResult.error.message);
      }
    });
  };

  const generateScenes = async () => {
    if (!state.videoName) {
      setState(prev => ({ ...prev, videoNameError: "Please enter a video name" }));
      return;
    }
  
    try {
      setState(prev => ({ ...prev, isAnalyzing: true, videoNameError: null }));
      
      await getDocumentName();
  
      let isDocEmpty:any = false;
      if (Office.context.host === Office.HostType.Word) {
        isDocEmpty = await isDocumentEmpty();
      }
  
      if (isDocEmpty) {
        setState(prev => ({ 
          ...prev, 
          videoNameError: "Your document appears to be empty or has very limited content.", 
          isAnalyzing: false 
        }));
        return;
      }
  
      localStorage.removeItem('successVideoId');
      var pdfUrl:any = ""
      try {
        pdfUrl = await getPdfUrl();        
      } catch (error) {
        console.log('Could not read office file', error);
      }

      if (!pdfUrl) {
        handleShowAlert("Failed to generate PDF URL", "danger");
      }
  
      const reqData = {
        inputUrl: pdfUrl,
        videoName: state.videoName,
        voiceName: state.selectedVoice.voiceName,
        selectedMusic: state.selectedMusic.audio_url,
        duration: state.duration,
        videoCaption: {
          is_selected: state.captionEnabled,
          caption_type: enterpriseVideoCaptions.find((x) => x.preview_url === state.selectedCaption)?.id,
        },
      };
  
      const response = await generateScenesRequest(reqData);
      if (response.status === "success") {
        setState(prev => ({ 
          ...prev, 
          videoSuccessId: response.id, 
          videoNameError: null 
        }));
        localStorage.setItem("successVideoId", response.id);
      } else {
        throw new Error("Scene generation failed");
      }
    } catch (error) {
      console.error("Error generating scenes:", error);
      handleShowAlert(error.message || "An error occurred while generating scenes.", "danger");
      setState(prev => ({ ...prev, isAnalyzing: false }));
    }
  };

  const renderFormGroup = (element) => {
    switch (element) {
      case "language":
        return state.showLanguage && (
          <div className="generate-form-group" key="language">
            <span className="generate-form-label">Language</span>
            <CustomSelect
              data={languages}
              value={state.selectedLanguage}
              options={languages}
              hasAudio={false}
              onChange={onLanguageChange}
              placeholder="English"
            />
          </div>
        );
      case "backgroundVideo":
        return state.showBackgroundVideo && (
          <div className="generate-form-group" key="backgroundVideo">
            <span className="generate-form-label">Background</span>
            <div className="form-content">
              <video
                className="form-video"
                key={state.selectedBackgroundVideo}
                width="151"
                height="85"
                onClick={() => showVideoDialog(true)}
              >
                <source src={state.selectedBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button className="form-button" onClick={onBackgroundVideoChange}>
                Change
              </button>
            </div>
          </div>
        );
      case "voice":
        return state.showVoice && (
          <div className="generate-form-group" key="voice">
            <span className="generate-form-label">Voice</span>
            <CustomSelect
              data={voices}
              value={state.selectedVoice ? state.selectedVoice.label : ""}
              options={voices.map((x) => x.label)}
              hasAudio={true}
              onChange={onVoiceChange}
              placeholder="Select Voice"
            />
          </div>
        );
      case "music":
        return state.showMusic && (
          <div className="generate-form-group" key="music">
            <span className="generate-form-label">Music</span>
            <CustomSelect
              data={musics}
              value={state.selectedMusic ? state.selectedMusic.label : ""}
              options={musics.map((x) => x.label)}
              hasAudio={true}
              onChange={onMusicChange}
              placeholder="Select Music"
            />
          </div>
        );
      case "videoCaption":
        return state.showVideoCaption && (
          <div className="generate-form-group" key="videoCaption">
            <span className="generate-form-label">Caption</span>
            <div className="form-content">
              <label className="toggle-switch">
                <input type="checkbox" id="video-caption" checked={state.captionEnabled} onChange={onCaptionChange} />
                <span className="switch" />
              </label>
              {state.captionEnabled && (
                <>
                  <video
                    className="form-video"
                    key={state.selectedCaption}
                    width="151"
                    height="85"
                    onClick={() => showVideoDialog(false)}
                  >
                    <source src={state.selectedCaption} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button className="form-button" onClick={onCaptionSelectionChange}>
                    Change
                  </button>
                </>
              )}
            </div>
          </div>
        );
      case "videoDuration":
        return state.showVideoDuration && (
          <div className="generate-form-group" key="videoDuration">
            <label className="generate-form-label">Duration</label>
            <div className="slider-container">
              <div className="slider-labels">
                <span className="duration-start-end-label">00:30</span>
                <span className="min-max-values-label">
                  <b>Min</b> : {formatDuration(state.duration[0])} - <b> Max</b> : {formatDuration(state.duration[1])}
                </span>
                <span className="duration-start-end-label">02:30</span>
              </div>
              <Range
                values={state.duration}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={handleSliderChange}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "36px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "3px",
                        width: "100%",
                        borderRadius: "4px",
                        background: calculateGradient(state.duration, MIN, MAX),
                        alignSelf: "center",
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, index }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      border: "none",
                      backgroundColor: index === 0 ? "#00B7EA" : "#FF4940",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 2px 6px #AAA",
                    }}
                  />
                )}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {state.isAnalyzing ? (
        <div className="analyze-container">
          {state.showAlert ? (
            <Alert key={state.alertVariant} show={state.showAlert} variant={state.alertVariant} className="custom-alert">
              <span className="orders-name">
                {state.contentExtractionFailedMessage}</span>
            </Alert>
          ) : (
            <ProgressBar isLoading={state.isAnalyzing} percent={state.progressBarPercent} size={"large"} showInfo={false} />
          )}
          <span>{localStorage.getItem('successVideoId')}</span>
        </div>
      ) : (
        <div className="generate-container">
<span className="title"> Generate a ready to publish video from your {Office.context.host === Office.HostType.PowerPoint ? 'Powerpoint' : 'Word Document'}</span>          <div className="generate-form-group">
            <span className="generate-form-label">Name</span>
            <input
              type="text"
              className="generate-form-input"
              placeholder="Enter video name"
              onChange={onNameChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  generateScenes();
                }
              }}
            />
          </div>

          {state.bottomContainerArray.map(renderFormGroup)}

          <div className="badge-selection-container">
            {!state.showLanguage && (
              <button
                className="badge-item"
                onClick={() => {
                  setState(prev => ({ ...prev, showLanguage: true }));
                  handleBottomContainerArray("language");
                }}
              >
                <img src="/assets/plus.png" alt="" />
                Language
              </button>
            )}
            {!state.showVoice && (
              <button
                className="badge-item"
                onClick={() => {
                  setState(prev => ({ ...prev, showVoice: true }));
                  handleBottomContainerArray("voice");
                }}
              >
                <img src="/assets/plus.png" alt="" />
                Voice
              </button>
            )}
            {!state.showBackgroundVideo && (
              <button
                className="badge-item"
                onClick={() => {
                  setState(prev => ({ ...prev, showBackgroundVideo: true }));
                  handleBottomContainerArray("backgroundVideo");
                }}
              >
                <img src="/assets/plus.png" alt="" />
                Background
              </button>
            )}
            {!state.showMusic && (
              <button
                className="badge-item"
                onClick={() => {
                  setState(prev => ({ ...prev, showMusic: true }));
                  handleBottomContainerArray("music");
                }}
              >
                <img src="/assets/plus.png" alt="" />
                Music
              </button>
            )}
            {!state.showVideoCaption && (
              <button
                className="badge-item"
                onClick={() => {
                  setState(prev => ({ ...prev, showVideoCaption: true }));
                  handleBottomContainerArray("videoCaption");
                }}
              >
                <img src="/assets/plus.png" alt="" />
                Caption
              </button>
            )}
            {!state.showVideoDuration && (
              <button
                className="badge-item"
                onClick={() => {
                  setState(prev => ({ ...prev, showVideoDuration: true }));
                  handleBottomContainerArray("videoDuration");
                }}
              >
                <img src="/assets/plus.png" alt="" />
                Duration
              </button>
            )}
          </div>

          {state.videoNameError && <span className="error-message">{state.videoNameError}</span>}

          <div className="action-container">
            <button
              className="footer-btn sf-generate-btn"
              onClick={generateScenes}
            >
              Generate Scenes
            </button>
          </div>
        </div>
      )}
      {/* <div style={{display:'none'}}>
        <span>Deployment Version is: 0.0.3</span>
      </div> */}
      <Footer />
      
    </>
  );
};

export default GeneratePage;