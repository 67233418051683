import React, { useEffect, useRef, useState } from "react";
import "../styles/scenes.css";
import "../styles/contentConfigurations.css";
import "../styles/inputSection.css";
import "../styles/scenesSelector.css";
import { voices, musics, languages } from "../constants/musics";
import Accordion from "react-bootstrap/Accordion";
import CustomSelect from "../components/dropdown";
import "../styles/scriptInput.css";
import axios from "axios";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { SceneModel, SceneConfigurationModel } from "../models/sceneOutputModel";
import { v4 as uuid } from "uuid";
import { PickerOverlay } from "filestack-react";
import "../styles/scenesFooter.css";
import "../styles/contentSelection.css";
import Badge from "react-bootstrap/Badge";
import videojs from "video.js";
import VideoJS from "../components/VideoJS";
import { BASE_API_URL } from "../constants/apiUrls";
import "../styles/coachMark.css";
import { getBackgroundVideos } from "../services/getBackgroundVideos";
import { enterpriseVideoCaptions } from "../constants/captions";
import { Range } from "react-range";
import { calculateGradient, formatDuration, MAX, MIN, MIN_DIFFERENCE, STEP } from "./generate";

const STT_URL = "https://fdwbfmq3eyxgu55pfdxj63m3hu0hexbt.lambda-url.us-east-1.on.aws/?module=textToSpeech";
const API_KEY = "KJCCnALRA6sTydLdR4NndLKKqkHa1We4";

const ScenesPage: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [selectedSceneIndex, setSelectedSceneIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const debounceTimeout = useRef<number | null>(null);
  const [selectedMusic, setSelectedMusic] = useState<any>();
  const [selectedVoice, setSelectedVoice] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [videoName, setVideoName] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [scriptInput, setScriptInput] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [maxValue, setMaxValue] = useState(450);
  const [showScriptInput, setShowScriptInput] = useState(false);
  const navigationNextRef = useRef<HTMLButtonElement | null>(null);
  const navigationPrevRef = useRef<HTMLButtonElement | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [changeDialogOpen, setChangeDialogOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isVideo, setIsVideo] = useState(false);
  const swiperRef = useRef<any>(null);
  const [requestBody, setRequestBody] = useState<SceneModel[]>([]);
  const [changeSelectedItemIndex, setChangeSelectedItemIndex] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [errorText, setErrorText] = useState("");
  const [showErrorSection, setShowErrorSection] = useState(false);
  const [pexelImagesResponse, setPexelImagesResponse] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [availablePageModal, setAvailablePageModal] = useState(true);
  const [fromLogo, setFromLogo] = useState(false);
  const [showVoiceOverModal, setShowVoiceOverModal] = useState(false);
  const [tempVoice, setTempVoice] = useState<any>();
  const [audioRef, setAudioRef] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>(null);
  const [changeSelectedItemInputIndex, setChangeSelectedItemInputIndex] = useState(0);
  const [fetchStatus, setFetchStatus] = useState("");
  const [searchPhrases, setSearchPhrases] = useState([]);
  const [isFromChangeModal, setIsFromChangeModal] = useState(false);
  const playerRef = React.useRef(null);
  const [showCoachMark, setShowCoachMark] = useState(false);
  const [coachMarkStepIndex, setCoachMarkStepIndex] = useState(0);
  const [selectedBackgroundVideo, setSelectedBackgroundVideo] = useState<string | null>(null);
  const [backgroundVideos, setBackgroundVideos] = useState(null);
  const [backgroundVideoOpen, setBackgroundVideoOpen] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [captionEnabled, setCaptionEnabled] = useState(false);
  const [selectedCaption, setSelectedCaption] = useState(null);
  const [isBackgroundSelected, setIsBackgroundSelected] = useState(false);
  const [isFromInputSection, setIsFromInputSection] = useState(false);
  const [duration, setDuration] = useState([50, 60]);
  const [activeKey, setActiveKey] = useState(null);
  const [editScenes, setEditScenes] = useState(false);
  const [showDeleteSceneModal, setShowDeleteSceneModal] = useState(false);
  const [showReOrderSceneModal, setShowReOrderSceneModal] = useState(false);
  const [showChooseEffectModal, setShowChooseEffectModal] = useState(false);
  const [configEffects, setConfigEffects] = useState([]);
  const [selectedEffectIndex, setSelectedEffectIndex] = useState(0);
  const [isEffectLoading, setIsEffectLoading] = useState(true);
  const [isEffectVideoDialogOpen, setIsEffectVideoDialogOpen] = useState(false);
  const [selectedEffect, setSelectedEffect] = useState(null);
  const [selectedEffectVideoUrl, setSelectedEffectVideoUrl] = useState(null);
  const [rollbackSceneData, setRollbackSceneData] = useState([]);

  const coachMarkSteps = [
    "You can review or change your inputs here",
    "Each scene in your video is represented by a number here",
    "The type and number of images, videos, text and/or GIF in each scene is shown here",
    "Review automated selections or replace them with one of your choice here",
  ];

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: videoUrl,
        type: "video/mp4",
      },
    ],
  };

  const videoJsOptionsEffect = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: selectedEffectVideoUrl,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  const getConfigurationIndex = () => {
    var configurationIndex = requestBody[selectedSceneIndex].sceneConfigs.indexOf(
      requestBody[selectedSceneIndex].sceneConfigs.filter((x) => x.isSelected)[0]
    );
    return configurationIndex >= 0 ? configurationIndex : 0;
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoName(event.target.value);
  };

  const handleVoiceChange = async (label: string) => {
    const sv = voices.filter((voice) => voice.label === label)[0];
    setTempVoice(sv);
    setShowVoiceOverModal(true);
  };

  const updateVoiceOvers = async () => {
    setShowVoiceOverModal(false);
    setIsLoading(true);
    const updatedScenes = await Promise.all(
      requestBody.map(async (scene) => {
        const currentScriptInput = scene.sceneConfigs.filter((x) => x.isSelected)[0].voiceOver?.text;
        if (currentScriptInput) {
          const response = await axios.post(
            STT_URL,
            {
              text: currentScriptInput,
              speed: 1,
              voiceName: tempVoice.voiceName,
              voiceLanguageCode: "en-US",
            },
            {
              headers: {
                api_key: API_KEY,
              },
            }
          );
          scene.sceneConfigs.forEach((sc) => {
            sc.voiceOver.text = currentScriptInput;
            sc.voiceOver.url = response.data.url;
            sc.voiceOver.duration = response.data.duration;
            sc.voiceOver.characterLength = currentScriptInput.length;
          });
        }
        return scene;
      })
    );

    setRequestBody(updatedScenes);
    setSelectedVoice(tempVoice);
    setIsLoading(false);
  };

  const handleMusicChange = (label: string) => {
    const sm = musics.filter((music) => music.label === label)[0];
    setSelectedMusic(sm);
  };

  const handleLanguageChange = (label: string) => {
    const sl = languages.filter((lang) => lang == label)[0];
    setSelectedLanguage(sl);
  };

  const handleSceneClick = (index: number) => {
    if (globalLoading) return;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    setGlobalLoading(true);
    debounceTimeout.current = window.setTimeout(() => {
      setSelectedSceneIndex(index);
      setSelectedEffectIndex(0);
      if (requestBody[index].sceneConfigs.filter((x) => x.isSelected)[0].voiceOver.text) {
        setCharCount(requestBody[index].sceneConfigs.filter((x) => x.isSelected)[0].voiceOver.text.length);
        setShowScriptInput(true);
        setIsPlaying(false);
        setScriptInput(requestBody[index].sceneConfigs.filter((x) => x.isSelected)[0].voiceOver.text);
        setMaxValue(data.scenes[index].data?.voice_over.text.length >= 150 ? data.scenes[index].data?.voice_over.text.length : 150);
        setIsEditing(false);
        // if (requestBody[index].sceneConfigs.filter((x) => x.isSelected)[0].voiceOver.text === "Enter script here (not more than 150 characters)") {
        //   setIsEditing(true);
        //   const updated_reqBody = [...requestBody];
        //   updated_reqBody[index].sceneConfigs.filter((x) => x.isSelected)[0].voiceOver.text = "";
        //   setMaxValue(150);
        //   setRequestBody(updated_reqBody);
        // }
      } else {
        if (!data.scenes[index].requirement.voice_over.required) {
          setShowScriptInput(false);
        }
      }
      setGlobalLoading(false);
    }, 500);
  };

  const handlePrevClick = () => {
    if (selectedSceneIndex > 0) {
      handleSceneClick(selectedSceneIndex - 1);
      setShowErrorSection(false);
    }
  };

  const handleNextClick = () => {
    if (selectedSceneIndex < data.scenes.length - 1) {
      handleSceneClick(selectedSceneIndex + 1);
      setShowErrorSection(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setScriptInput(value);
    requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].voiceOver.text = event.target.value;
    setCharCount(event.target.value.length);
  };

  const handleEditChange = () => {
    setIsEditing(true);
    if (audioRef) {
      handleStop();
    }
    setIsPlaying(false);
  };

  const updateVoiceOVer = async () => {
    try {
      const response = await axios.post(
        STT_URL,
        {
          text: scriptInput,
          speed: 1,
          voiceName: selectedVoice.voiceName,
          voiceLanguageCode: "en-US",
        },
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );
      requestBody[selectedSceneIndex].sceneConfigs.forEach((x) => {
        x.voiceOver.text = scriptInput;
        x.voiceOver.url = response.data.url;
        x.voiceOver.duration = response.data.duration;
        x.voiceOver.characterLength = scriptInput.length;
      });
    } catch (error) {
      console.error("Error during TTS request:", error);
    }
  };

  const handleProcessing = async () => {
    setIsEditing(false);
    setIsProcessing(true);
    await updateVoiceOVer();
    setIsPlaying(false);
    setIsProcessing(false);
  };

  const handlePlay = () => {
    let audio = new Audio(requestBody[selectedSceneIndex].sceneConfigs.filter((x) => x.isSelected)[0].voiceOver.url);
    setAudioRef(audio);
    audio.play();
    setIsPlaying(true);
    audio.addEventListener("ended", () => {
      setIsPlaying(false);
    });
  };

  const handleStop = () => {
    setIsPlaying(false);
    audioRef.pause();
  };

  const handleCheckboxChange = (index: number, event: any) => {
    var a = event;
    requestBody[selectedSceneIndex].sceneConfigs.forEach((config, configIndex) => {
      if (index !== configIndex) {
        config.isSelected = false;
      }
    });
    requestBody[selectedSceneIndex].sceneConfigs[index].isSelected = true;
    setRequestBody(JSON.parse(JSON.stringify(requestBody)));
  };

  const handleTextChange = (index, value) => {
    const updatedRequestBody = [...requestBody];
    updatedRequestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].texts[index].value = value;
    setRequestBody(updatedRequestBody);
    if (errorText === "Please enter a title. " && index === 0) {
      setErrorText("");
    } else if (errorText === "Please enter a website url. " && index === 0) {
      setErrorText("");
    } else if (errorText === "Please enter a text. ") {
      setErrorText("");
    }
  };

  const handleSeeExampleClick = (
    url: string,
    mediaType: string = "video",
    isFromChangeModal = false,
    isFromInputSection = false
  ) => {
    setIsFromChangeModal(isFromChangeModal);
    setIsFromInputSection(isFromInputSection);
    setVideoUrl(null);
    setImageUrl(null);
    if (mediaType === "image") {
      setImageUrl(url);
    } else {
      setVideoUrl(url);
    }
    setDialogOpen(true);

    isFromChangeModal;

    if (changeDialogOpen) {
      setChangeDialogOpen(false);
    }
  };

  const handleShowVoiceOver = () => {
    setShowVoiceOverModal(false);
  };

  const handleChangeClick = (isVideo: boolean, selectedItemIndex, fromLogo) => {
    setFromLogo(fromLogo);
    var uploaded_images =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images?.uploaded_images;
    var available_images =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images?.available_images;

    var uploaded_videos =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.uploaded_videos;
    var available_videos =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.available_videos;

    if (!available_images && uploaded_images) {
      setChangeSelectedItemIndex(999999);
    }
    if (!available_videos && uploaded_videos) {
      setChangeSelectedItemInputIndex(999999);
    }

    setPexelImagesResponse([]);
    setIsVideo(isVideo);

    if (isVideo) {
      var selectedVideo =
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex];
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.available_videos?.forEach(
        (video, index) => {
          if (video.thumbnailUrl === selectedVideo.thumbnail) {
            setChangeSelectedItemIndex(index);
            setChangeSelectedItemInputIndex(999999);
            setAvailablePageModal(true);
          }
        }
      );
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.uploaded_videos?.forEach(
        (video, index) => {
          if (video.thumbnailUrl === selectedVideo.thumbnail) {
            setChangeSelectedItemInputIndex(index);
            setChangeSelectedItemIndex(9999999);
            setAvailablePageModal(true);
          }
        }
      );
    }
    else {

      var selectedImage =
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex];
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images?.available_images?.forEach(
        (image, index) => {
          if (image.src.original === selectedImage.original) {
            setChangeSelectedItemIndex(index);
            setChangeSelectedItemInputIndex(999999);
          }
        }
      );
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images?.uploaded_images?.forEach(
        (image, index) => {
          if (image.src.original === selectedImage.original) {
            setChangeSelectedItemInputIndex(index);
            setChangeSelectedItemIndex(999999);
          }
        }
      );
    }

    if ((isVideo && (!uploaded_videos?.length || !available_videos?.length)) ||
      (!available_images?.length || !uploaded_images?.length)) {
      setAvailablePageModal(false);
    }
    else {
      setAvailablePageModal(true);
    }

    if (isVideo && (uploaded_videos?.length || available_videos?.length)) {
      setAvailablePageModal(true);
    }
    if (available_images?.length || uploaded_images?.length) {
      setAvailablePageModal(true);
    }
    setChangeDialogOpen(true);

    setSelectedItemIndex(selectedItemIndex);
  };

  const handleCloseDialog = () => {
    if (!changeDialogOpen && isFromChangeModal) {
      setChangeDialogOpen(true);
    }

    setDialogOpen(false);
    setVideoUrl(null);
  };

  const handleChangeCloseDialog = () => {
    handleSearchPhrases();
    setChangeDialogOpen(false);
    setIsFromChangeModal(false);
  };

  const setImages = (scene: any, configurationIndex) => {

    try {
      var images = [];
      if (scene.is_additional_scene) {
        const image = scene.scene_options[configurationIndex]?.requirement?.image;
        if (image > 0) {
          for (let i = 0; i < image.requiredCount; i++) {
            var obj5 = {
              type: "",
              original: "",
            };
            images.push(obj5);
          }
        }
        return images;
      }
      else {
        var tempSceneIndex = +localStorage.getItem("tempSceneIndex");
        if (scene.scene_options[configurationIndex].requirement.image) {
          const image = scene.scene_options[configurationIndex].requirement.image;
          if (scene.scene_options[configurationIndex].data?.images?.uploaded_images?.length > image.requiredCount) {
            var currentUploadedIndex = Number(localStorage.getItem("currentUploadIndex"));
            if (image.logo) {
              var obj = {
                type: "logo",
                original:
                  scene.scene_options[configurationIndex].data?.images?.uploaded_images[currentUploadedIndex]?.src
                    ?.original ?? "",
              };
              images.push(obj);
              if (tempSceneIndex !== scene.id) {
                localStorage.setItem("currentUploadIndex", (currentUploadedIndex + 1).toString());
                localStorage.setItem("tempSceneIndex", (tempSceneIndex + 1).toString());
              }
            } else {
              for (let i = 0; i < image.requiredCount; i++) {
                var currentUploadedIndex2 = Number(localStorage.getItem("currentUploadIndex"));
                var test =
                  currentUploadedIndex2 >= scene.scene_options[configurationIndex].data?.images?.uploaded_images?.length || " ";
                var obj2 = {
                  type: "",
                  original: test
                    ? scene.scene_options[configurationIndex].data?.images?.available_images[i]?.src?.original
                    : scene.scene_options[configurationIndex].data?.images?.uploaded_images[currentUploadedIndex2]?.src
                      .original || "",
                };
                if (tempSceneIndex !== scene.id) {
                  localStorage.setItem("currentUploadIndex", (currentUploadedIndex2 + 1).toString());
                  localStorage.setItem("tempSceneIndex", (tempSceneIndex + 1).toString());
                }
                images.push(obj2);
              }
            }
          } else {
            if (image.logo) {
              for (let i = 0; i < image.requiredCount; i++) {
                if (scene.scene_options[configurationIndex].data?.images?.uploaded_images && scene.scene_options[configurationIndex].data?.images?.uploaded_images[i]) {
                  var obj3 = {
                    type: "logo",
                    original: scene.scene_options[configurationIndex].data?.images?.uploaded_images[i].src?.original || ""

                  };
                  images.push(obj3);
                }
                else if (scene.scene_options[configurationIndex].data?.images?.available_images && scene.scene_options[configurationIndex].data?.images?.available_images[i]) {
                  var obj23 = {
                    type: "logo",
                    original: scene.scene_options[configurationIndex].data?.images?.available_images[i].src?.original || ""

                  };
                  images.push(obj23);
                }
                else {
                  var obj33 = {
                    type: "logo",
                    original: ""

                  };
                  images.push(obj33);
                }

              }
            } else {
              for (let i = 0; i < image.requiredCount; i++) {
                var obj2 = {
                  type: "",
                  original: scene.scene_options[configurationIndex].data?.images?.available_images[i]?.src?.original || "",
                };
                images.push(obj2);
              }
            }
          }
        }
      }
      return images;
    } catch (error) {
      console.error(error);
      return [];
    }


  };

  const setVideos = (scene: any, configurationIndex) => {
    try {
      var videos = [];
      if (scene.scene_options[configurationIndex].requirement.video) {
        const video = scene.scene_options[configurationIndex].requirement.video;
        if (scene.scene_options[configurationIndex].data?.videos?.available_videos?.length >= video.requiredCount) {
          for (let i = 0; i < video.requiredCount; i++) {
            const videoItem = scene.scene_options[configurationIndex].data?.videos?.available_videos[i];
            if (videoItem?.previewUrl) {
              var obj2 = {
                type: "video",
                thumbnail: scene.scene_options[configurationIndex].data?.videos?.available_videos[i]?.thumbnailUrl || "",
                generateDownloadUrl:
                  scene.scene_options[configurationIndex].data?.videos?.available_videos[i]?.generateDownloadUrl || "",
                url: scene.scene_options[configurationIndex].data?.videos?.available_videos[i]?.previewUrl || "",
                duration: scene.scene_options[configurationIndex].data?.videos?.available_videos[i]?.video_duration_in_seconds || "",
              };
              videos.push(obj2);
            }
            else {
              var obj13 = {
                type: "video",
                thumbnail: "",
                generateDownloadUrl: "",
                url: "",
                duration: "",
              };
              videos.push(obj13);
            }
          }
        }
        else {
          for (let i = 0; i < video.requiredCount; i++) {
            var obj7 = {
              type: "video",
              thumbnail: "",
              generateDownloadUrl: "",
              url: "",
              duration: "",
            };
            videos.push(obj7);
          }
        }
      }

      if (scene.scene_options[configurationIndex].requirement.video_upload) {
        const videoUpload = scene.scene_options[configurationIndex].requirement.video_upload;
        for (let i = 0; i < videoUpload.requiredCount; i++) {
          var emptyVideo = {
            type: "video",
            thumbnail: "",
            generateDownloadUrl: "",
            url: "",
            duration: "",
          };
          videos.push(emptyVideo);
        }
      }

      return videos;
    } catch (error) {
      console.error(error);
      return [];
    }

  };

  const setTexts = (scene: any, configurationIndex) => {
    try {
      var texts = [];

      if (scene.scene_options[configurationIndex].requirement.text) {
        scene.scene_options[configurationIndex].requirement.text.forEach((element, index) => {
          var value = "";

          if (scene.id === 1 && index === 0 && scene.scene_options[configurationIndex].data?.title) {
            value = scene.scene_options[configurationIndex].data.title;
            element.characterLength =
              element.characterLength > scene.scene_options[configurationIndex].data.title.length
                ? element.characterLength
                : scene.scene_options[configurationIndex].data.title.length;
          } else if (
            scene.id === +localStorage.getItem("sceneLength") &&
            index === 0 &&
            scene.scene_options[configurationIndex].data?.url
          ) {
            value = scene.scene_options[configurationIndex].data.url;
            element.characterLength =
              element.characterLength > scene.scene_options[configurationIndex].data.url.length
                ? element.characterLength
                : scene.scene_options[configurationIndex].data.url.length;
          } else if (scene.scene_options[configurationIndex].data?.text) {
            value = scene.scene_options[configurationIndex].data.text[index];
          }
          var obj = { ...element, value: value };
          texts.push(obj);
        });
      }

      return texts;
    } catch (error) {
      console.error(error);
      return [];
    }

  };

  const setVoiceOver = (scene: any) => {
    var voiceOver = {};
    if (scene.requirement?.voice_over?.required) {
      if (scene.data?.voice_over) {
        voiceOver = JSON.parse(JSON.stringify(scene.data?.voice_over));
      }
      else {
        voiceOver = {
          text: "",
          url: ""

        }
      }
    }

    return voiceOver;
  };

  const setSceneObject = (scene, configurationIndex, is_additional_scene) => {
    try {
      var scene_type = ""
      if (scene.id === 1) {
        scene_type = "intro"
      }
      if (scene.id === +localStorage.getItem("sceneLength")) {
        scene_type = "outro"
      }
      if (scene.original_id === undefined) {
        delete scene.original_id;
      }

      var sceneObj: SceneConfigurationModel = {
        id: scene.id,
        images: setImages(scene, configurationIndex),
        texts: setTexts(scene, configurationIndex),
        videos: setVideos(scene, configurationIndex),
        voiceOver: setVoiceOver(scene),
        is_additional_scene: is_additional_scene,
        scene_type: scene_type,
        segment_id: scene.scene_options[configurationIndex].segment_id,
        enterprise_scene_id: scene.scene_options[configurationIndex].id,
        isSelected: configurationIndex === 0 ? true : false,
        scene_options_ids: scene.scene_options.map((x) => x.id),
      };
      if (scene.original_id) {
        sceneObj.original_id = scene.original_id;
      }
      return sceneObj;
    } catch (error) {
      throw new Error(error);
    }



  };

  const getAvailableImageCount = () => {
    var available_images =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images?.available_images;
    var availableImageCount = available_images ? available_images.length : 0;
    var uploaded_images =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images?.uploaded_images;
    var uploadedImageCount = uploaded_images ? uploaded_images.length : 0;
    return availableImageCount + uploadedImageCount;
  };

  const getAvailableVideoCount = () => {
    var available_videos =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.available_videos;
    var availableVideoCount = available_videos ? available_videos.length : 0;
    var uploaded_videos =
      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.uploaded_videos;
    var uploadedVideoCount = uploaded_videos ? uploaded_videos.length : 0;
    return availableVideoCount + uploadedVideoCount;
  };

  const checkSegmentIds = (arr1, arr2) => {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all elements are equal
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }

  const getSelectedConfigurationFromUserInput = (scene: any, initialRequestBody: SceneModel[]) => {
    initialRequestBody
      .filter((x) => x.sceneIndex + 1 === scene.id)
      .forEach((scn) => {
        scn.sceneConfigs.forEach((scnConfig) => {
          if (checkSegmentIds(scnConfig.segment_id, scene.segment_id)) {
            scnConfig.isSelected = true;
            scnConfig.videos = scene.videos;
            scnConfig.images = scene.images;
            scnConfig.texts = scene.texts;
            scnConfig.voiceOver = scene.voiceOver;
          }
          else {
            scnConfig.isSelected = false;
          }
        });
      });
  };

  const fetchData = async () => {
    localStorage.setItem("currentUploadIndex", "0");
    localStorage.setItem("tempSceneIndex", "1");
    const videoSuccessIdStart = window.location.href.indexOf('videoSuccessId=') + 'videoSuccessId='.length;
    const id = window.location.href.substring(videoSuccessIdStart).split('&')[0];

    const response = await fetch(`${BASE_API_URL}/enterprise-dynamic-video-automation/${id}`);
    const result = await response.json();
    if (result.status === "completed") {
      const tempResData = result.data;
      tempResData.scenes = result.data.scenes.map((sc, index) => {
        return {
          ...sc,
          scene_options: sc.scene_options.filter(
            (option) => !option.scene_sequence_id.includes('recording')
          ).
            filter(
              (option) => !option.scene_sequence_id.includes('video_upload')
            ),
          original_id: result.data.user_input
            ? result.data.user_input.scenes[index].original_id
            : index + 1,
          is_additional_scene: result.data.user_input
            ? result.data.user_input.scenes[index].is_additional_scene
            : false,
        };
      });

      setData(tempResData);
      setSelectedSceneIndex(0);
      localStorage.setItem("sceneLength", result.data.scenes.length);
      setSelectedMusic(musics.filter((x) => x.audio_url === result.data.music.asset_url)[0]);
      setSelectedVoice(voices.filter((x) => x.voiceName === result.data.voice.voiceName)[0]);
      setSelectedBackgroundVideo(result.data.background_video.url);

      if (result.data.video_caption.is_selected) {
        setCaptionEnabled(true);

        var selectedCaption = enterpriseVideoCaptions.filter((x) => x.id === result.data.video_caption.caption_type)[0];
        setSelectedCaption(selectedCaption.preview_url);
      }

      if (result.data.video_duration) {
        setDuration([result.data.video_duration.durationMin, result.data.video_duration.durationMax]);
      }

      setVideoName(result.data.video_title);
      if (result.data?.website_images?.length > 0) {
        result.data.scenes.forEach((scene) => {
          scene.scene_options.forEach((scene_config) => {
            if (scene_config.requirement.image) {
              for (let i = 0; i < result.data.website_images.length; i++) {
                var upload_image = {
                  src: {
                    original: result.data.website_images[i],
                  },
                  type: "",
                };
                if (scene_config.data?.images?.uploaded_images) {
                  scene_config.data?.images?.uploaded_images.push(upload_image);
                } else {
                  scene_config.data.images = {
                    ...scene_config.data.images,
                    uploaded_images: [
                      {
                        src: upload_image.src,
                        type: upload_image.type,
                      },
                    ],
                  };
                }
              }
            }
          });
        });
      }
      var initialRequestBody = [];
      const isEditMode = localStorage.getItem("isEditMode");
      result.data.scenes.forEach((scene, index) => {
        var obj: SceneModel = {
          sceneIndex: index,
          sceneConfigs: [],
        };
        var scnConfig = [];
        scene.scene_options
          .filter(option => !option.scene_sequence_id?.includes('recording'))
          .filter(
            (option) => !option.scene_sequence_id.includes('video_upload')
          )
          .forEach((option, i) => {
            var a = option;

            if (isEditMode !== "edit") {
              scnConfig.push(setSceneObject(scene, i, false));
            } else {
              scnConfig.push(setSceneObject(scene, i, result.data.user_input.scenes[index].is_additional_scene));
            }
          });

        obj.sceneConfigs = scnConfig;
        initialRequestBody.push(obj);
      });
      if (result.data.user_input) {
        result.data.user_input.scenes.forEach((userScn) => {
          getSelectedConfigurationFromUserInput(userScn, initialRequestBody);
        });
      }
      setRequestBody(initialRequestBody);
      setFetchStatus("completed");
    } else {
      fetchData();
    }
  };

  const saveChangedImage = () => {
    if (availablePageModal) {
      if (changeSelectedItemIndex > 1000) {
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex].original =
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images?.uploaded_images[
            changeSelectedItemInputIndex
          ].src.original;
      } else {
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex].original =
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images?.available_images[
            changeSelectedItemIndex
          ].src.original;
      }
    } else {
      requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex].original =
        JSON.parse(JSON.stringify(pexelImagesResponse[changeSelectedItemIndex].src.original));
      if (data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images) {
        if (data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images.available_images) {
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images.available_images.push({
            id: Math.floor(Math.random()) * 1000,
            src: {
              original: pexelImagesResponse[changeSelectedItemIndex].src.original,
            },
          });
        } else {
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images = {
            ...data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images,
            available_images: [
              {
                id: Math.floor(Math.random()) * 1000,
                src: {
                  original: pexelImagesResponse[changeSelectedItemIndex].src.original,
                },
              },
            ],
          };
        }
      } else {
        data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data = {
          ...data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data,
          images: {
            available_images: [
              {
                id: Math.floor(Math.random()) * 1000,
                src: {
                  original: pexelImagesResponse[changeSelectedItemIndex].src.original,
                },
              },
            ],
          },
        };
      }
    }
    if (errorText.includes("Please upload a logo")) {
      setErrorText("");
    }

    setData(data);
    setRequestBody(JSON.parse(JSON.stringify(requestBody)));
    setChangeDialogOpen(false);
    setDialogOpen(false);
  };

  const saveChangedVideo = () => {
    if (isFromInputSection) {
      handleVideoSelect(selectedVideoIndex);
    } else {
      if (availablePageModal) {
        if (changeSelectedItemIndex > 1000) {
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].url =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.uploaded_videos[
              changeSelectedItemInputIndex
            ].previewUrl;
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].thumbnail =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.uploaded_videos[
              changeSelectedItemInputIndex
            ].thumbnailUrl;
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[
            selectedItemIndex
          ].generateDownloadUrl =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.uploaded_videos[
              changeSelectedItemInputIndex
            ].generateDownloadUrl;
        } else {
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].url =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.available_videos[
              changeSelectedItemIndex
            ].previewUrl;
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].thumbnail =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.available_videos[
              changeSelectedItemIndex
            ].thumbnailUrl;
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[
            selectedItemIndex
          ].generateDownloadUrl =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.available_videos[
              changeSelectedItemIndex
            ].generateDownloadUrl;
          requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].duration =
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos?.available_videos[
              changeSelectedItemIndex
            ].video_duration_in_seconds;
        }
      } else {
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].url =
          pexelImagesResponse[changeSelectedItemIndex].stockItem.previewUrl;
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].thumbnail =
          pexelImagesResponse[changeSelectedItemIndex].stockItem.thumbnailUrl;
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[
          selectedItemIndex
        ].generateDownloadUrl =
          pexelImagesResponse[changeSelectedItemIndex].stockItemFormats[
            pexelImagesResponse[changeSelectedItemIndex].stockItemFormats.length - 1
          ].generateDownloadUrl;
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].duration =
          pexelImagesResponse[changeSelectedItemIndex].stockItem.duration;
        if (data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos?.available_videos) {
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos.available_videos.push({
            previewUrl: pexelImagesResponse[changeSelectedItemIndex].stockItem.previewUrl,
            thumbnailUrl: pexelImagesResponse[changeSelectedItemIndex].stockItem.thumbnailUrl,
            generateDownloadUrl:
              pexelImagesResponse[changeSelectedItemIndex].stockItemFormats[
                pexelImagesResponse[changeSelectedItemIndex].stockItemFormats.length - 1
              ].generateDownloadUrl,
            video_duration_in_seconds: pexelImagesResponse[changeSelectedItemIndex].stockItem.duration,
          });
        }
      }

      setRequestBody(JSON.parse(JSON.stringify(requestBody)));
      setData(JSON.parse(JSON.stringify(data)));
      setChangeDialogOpen(false);
    }
    setDialogOpen(false);
  };

  const onUploadDone = (res: any) => {
    if (isVideo) {
      if (requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex]) {
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].url =
          res.filesUploaded[0].url;
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[selectedItemIndex].thumbnail =
          res.filesUploaded[0].url;
        requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].videos[
          selectedItemIndex
        ].generateDownloadUrl = res.filesUploaded[0].url;
        if (data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos.uploaded_videos) {
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos.uploaded_videos.push({
            previewUrl: res.filesUploaded[0].url,
            thumbnailUrl: res.filesUploaded[0].url,
            generateDownloadUrl: res.filesUploaded[0].url,
          });
        } else {
          if (
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data
          ) {
            data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.videos = {
              ...data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos,
              uploaded_videos: [
                {
                  previewUrl: res.filesUploaded[0].url,
                  thumbnailUrl: res.filesUploaded[0].url,
                  generateDownloadUrl: res.filesUploaded[0].url,
                },
              ],
            };
          }
        }
      }
    } else {
      requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex].original =
        res.filesUploaded[0].url;
      if (!data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images) {
        data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data = {
          ...data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data,
          images: {
            uploaded_images: [
              {
                type: requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex]
                  .type,
                src: {
                  original: res.filesUploaded[0].url,
                },
              },
            ],
          },
        };
      } else {
        if (data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images.uploaded_images) {
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images.uploaded_images.push({
            type: requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex].type,
            src: {
              original: res.filesUploaded[0].url,
            },
          });
        } else {
          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images = {
            ...data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.images,
            uploaded_images: [
              {
                type: requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].images[selectedItemIndex]
                  .type,
                src: {
                  original: res.filesUploaded[0].url,
                },
              },
            ],
          };
        }
      }
    }
    setData(data);
    setShowUpload(false);
    setChangeDialogOpen(false);
  };

  const searchPexelImages = async (mediaType: string) => {
    setIsLoading(true);
    var pexelImages = (document.getElementById("pexel-input") as HTMLInputElement).value;
    var sp = searchPhrases;
    if (!searchPhrases.includes(pexelImages)) {
      sp.push(pexelImages);
    }
    setSearchPhrases(sp);
    const response = await axios.post(
      "https://fdwbfmq3eyxgu55pfdxj63m3hu0hexbt.lambda-url.us-east-1.on.aws/?module=mediaSearch",
      {
        mediaType: mediaType,
        searchTerm: pexelImages,
        mediaParams: {
          page: 1,
          numberResultsPerPage: 20,
        },
      },
      {
        headers: {
          api_key: "KJCCnALRA6sTydLdR4NndLKKqkHa1We4",
        },
      }
    );
    setPexelImagesResponse([]);
    const responseData = response.data;
    const imagesArray = Object.keys(responseData)
      .filter(key => !isNaN(Number(key)))
      .map(key => responseData[key]);
    setPexelImagesResponse(imagesArray);
    setIsLoading(false);
  };

  const goToCheckout = async () => {
    var finalInput = [];
    var errText = "";
    var isFinished = false;
    requestBody.forEach((scene, index) => {
      var selected = scene.sceneConfigs.filter((x) => x.isSelected)[0];
      if (selected.images.length > 0) {
        selected.images.forEach((img) => {
          if (img.original.length <= 0 && !isFinished) {
            errText += img.type === "logo" ? `Please upload a logo!` : `Please upload images(s). Minimum ${selected.images.length} image is required for this scene!`;
            handleSceneClick(index);
            isFinished = true;
          }
        });
      }
      if (selected.texts.length > 0) {
        selected.texts.forEach((txt, txtIndex) => {
          if (txt.value.length <= 0 && !isFinished) {
            if (selected.texts[txtIndex].label === "title") {
              errText += `Please enter a title. `;
              isFinished = true;
            } else if (selected.texts[txtIndex].label === "url") {
              errText += `Please enter a url. `;
              isFinished = true;
            } else if (index !== 0) {
              errText += `Please enter a text. `;
              isFinished = true;
            }
            handleSceneClick(index);
          }
        });
      }
      if (selected.videos.length > 0) {
        selected.videos.forEach((vid) => {
          if (vid.s3Url) {
            vid.s3Url = "";
          }
          if (vid.s3generateDownloadUrl) {
            vid.s3generateDownloadUrl = ""
          }
          if (vid.url.length <= 0 && !isFinished) {
            errText += `Please upload video(s). Minimum ${selected.videos.length} video is required for this scene!`;
            handleSceneClick(index);
            isFinished = true;
          }
        })
      }
      if (data.scenes[index].requirement.voice_over.required && selected.voiceOver.text.length <= 0 && !isFinished) {
        errText += "Please enter a valid script text and generate audio for it."
        handleSceneClick(index);
        isFinished = true;
      }
    });
    if (errText.length > 0) {
      setShowErrorSection(true);
      setErrorText(errText);
      return;
    }
    requestBody.forEach((scene) => {
      var selectedConfig = JSON.parse(JSON.stringify(scene.sceneConfigs.filter((x) => x.isSelected)[0]));
      delete selectedConfig.isSelected;
      selectedConfig.videos.forEach((element) => {
        delete element.duration;
      });
      finalInput.push(selectedConfig);
    });

    let token = localStorage.getItem("vmPluginAccessToken");
    localStorage.setItem("cartId", uuid());
    const isEditMode = localStorage.getItem("isEditMode");
    var videoCaption;

    videoCaption = {
      is_selected: captionEnabled,
      caption_type: captionEnabled
        ? enterpriseVideoCaptions.filter((x) => x.preview_url === localStorage.getItem("selectedCaption"))[0].id
        : "default",
    };

    var user_input_with_mode: any = {
      id: localStorage.getItem("successVideoId"),
      source_platform: "add_ins",
      mode: "user-input",
      music: {
        asset_url: selectedMusic.audio_url,
      },
      language: selectedLanguage,
      video_title: videoName,
      voice: {
        speed: 1,
        voiceName: selectedVoice.voiceName,
        voiceLanguageCode: selectedVoice.voiceLanguageCode,
      },
      video_duration: {
        durationMax: localStorage.getItem("durationMax"),
        durationMin: localStorage.getItem("durationMin"),
      },
      scenes: finalInput,
      video_caption: videoCaption,
      background_video: {
        url: localStorage.getItem("selectedBackgroundVideo"),
      },
      video_creation_mode: "create_edit",
    };
    if (isEditMode === "edit") {
      user_input_with_mode = {
        ...user_input_with_mode,
        rerender: "true",
        video_creation_mode: "existing_video_edit",
      };
    }
    const response = await axios.post(
      `${BASE_API_URL}/add-ins-cart`,
      {
        template_ids: ["GENERAL_AUTOMATED"],
        input_url: data.input_url,
        cart_id: localStorage.getItem("cartId"),
        user_id: localStorage.getItem("userId"),
        orientation: ["landscape"],
        source: "add-ins",
        mode: "enterprise",
        user_input: user_input_with_mode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.status === "success") {
      var video_generation_response = await axios.post(`${BASE_API_URL}/add-ins-video-generation`, {
        cart_id: localStorage.getItem("cartId"),
      });

      Office.context.ui.messageParent(
        JSON.stringify({
          status: "ok",
          data: video_generation_response.data,
        })
      );
    }
  };

  const getDuration = (duration) => {
    if (duration === 0) {
      return <></>
    }
    var min = Math.floor(duration / 60);
    var seconds = duration - min * 60;
    return (
      <>
        <span>
          {`0${min}`}:{seconds < 10 ? "0" + seconds : seconds}
        </span>
      </>
    );
  };

  const handleSearchPhrases = () => {
    setSearchPhrases([]);
    if (data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.searchPhrases) {
      setSearchPhrases(data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.searchPhrases);
    }
  };

  useEffect(() => {
    const scrollAmount = 58;

    var leftArrow = document.querySelector('.left-arrow');
    if (leftArrow) {
      leftArrow.addEventListener('click', () => {
        document.querySelector('.scenes-wrapper').scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      });
    }

    var rightArrow = document.querySelector('.right-arrow');
    if (rightArrow) {
      rightArrow.addEventListener('click', () => {
        document.querySelector('.scenes-wrapper').scrollBy({ left: scrollAmount, behavior: 'smooth' });
      });
    }
  });

  useEffect(() => {
    if (fetchStatus !== "completed") {
      fetchData();
    }
  }, [fetchStatus]);

  const handleCoachMark = () => {
    if (!showCoachMark) {
      document.querySelector("body").style.overflowY = "hidden";
      window.scrollTo(0, 0);
      setActiveKey(null);
    }
    setShowCoachMark(!showCoachMark);
  };

  useEffect(() => {
    var hasCoachMarked = localStorage.getItem("hasCoachMarked");

    if (!hasCoachMarked || +hasCoachMarked === 0) {
      localStorage.setItem("hasCoachMarked", "1");
      handleCoachMark();
    }
  });

  if (!data || requestBody.length <= 0) {
    return <div className="loading-container">Loading...</div>;
  }

  const setCoachMarkStep = () => {
    var currentStepIndex = coachMarkStepIndex;

    setCoachMarkStepIndex(currentStepIndex + 1);

    if (currentStepIndex + 1 === 3) {
      window.scrollTo(0, document.body.scrollHeight);
    } else if (currentStepIndex === 3) {
      setShowCoachMark(false);
      setCoachMarkStepIndex(0);
      localStorage.setItem("hasCoachMarked", "1");
      document.querySelector("body").style.overflowY = "unset";
    }
  };

  const onBackgroundVideoChange = async (isCaption = false) => {
    if (!isCaption) {
      setIsBackgroundSelected(true);
      const data = await getBackgroundVideos(0, 50);
      setBackgroundVideos(data.data);
      data.data.forEach((currData, index) => {
        if (currData.asset_url.landscape == localStorage.getItem("selectedBackgroundVideo")) {
          setSelectedVideoIndex(index);
        }
      });

      setBackgroundVideoOpen(true);
    } else {
      setIsBackgroundSelected(false);
      enterpriseVideoCaptions.forEach((enterpriseVideoCaption, index) => {
        if (enterpriseVideoCaption.preview_url == localStorage.getItem("selectedCaption")) {
          setSelectedVideoIndex(index);
        }
      });

      setBackgroundVideoOpen(true);
    }
  };

  const handleBackgroundClose = () => {
    setBackgroundVideoOpen(false);
  };

  const handleVideoSelect = async (selectedVideoIndex) => {
    if (isBackgroundSelected) {
      localStorage.setItem("selectedBackgroundVideo", backgroundVideos[selectedVideoIndex].asset_url.landscape);
      setSelectedBackgroundVideo(backgroundVideos[selectedVideoIndex].asset_url.landscape);
    } else {
      localStorage.setItem("selectedCaption", enterpriseVideoCaptions[selectedVideoIndex].preview_url);
      setSelectedCaption(enterpriseVideoCaptions[selectedVideoIndex].preview_url);
    }

    setTimeout(() => {
      console.log("Wait for update localstorage");
    }, 500);
    setBackgroundVideoOpen(false);
  };

  const onCaptionChange = () => {
    setCaptionEnabled(!captionEnabled);
  };

  const handleSliderChange = (values) => {
    const [newMin, newMax] = values;
    if (newMax - newMin >= MIN_DIFFERENCE) {
      setDuration(values);
    } else if (newMin >= duration[1] - MIN_DIFFERENCE) {
      setDuration([duration[1] - MIN_DIFFERENCE, duration[1]]);
    } else if (newMax <= duration[0] + MIN_DIFFERENCE) {
      setDuration([duration[0], duration[0] + MIN_DIFFERENCE]);
    }

    // setDuration(values);
    localStorage.setItem("durationMin", values[0]);
    localStorage.setItem("durationMax", values[1]);
  };

  const getMediaSequence = (sequenceId: string) => {
    // These patterns look for number_type format, accounting for plural forms
    // For video, add negative lookahead (?!_upload) to avoid matching video_upload
    const videoPattern = /\d+_videos?(?!_upload)/;
    const videoUploadPattern = /\d+_video_upload/;
    const textPattern = /\d+_texts?/;
    const imagePattern = /\d+_images?/;
    const thankyouPattern = /\d+_thank_you/;

    // Find indices of the patterns in the string
    const videoIndex = sequenceId.search(videoPattern);
    const videoUploadIndex = sequenceId.search(videoUploadPattern);
    const textIndex = sequenceId.search(textPattern);
    const imageIndex = sequenceId.search(imagePattern);
    const thankyouIndex = sequenceId.search(thankyouPattern);

    const sequenceArray = [
      { type: "video", index: videoIndex },
      { type: "video_upload", index: videoUploadIndex },
      { type: "text", index: textIndex },
      { type: "image", index: imageIndex },
      { type: "thank_you", index: thankyouIndex },
    ];

    // Filter out types that weren't found in the string
    const filteredArray = sequenceArray.filter(item => item.index !== -1);

    // Sort the array by the position in the original string
    return filteredArray.sort(({ index: a }, { index: b }) => a - b);
  };

  const toggleAccordionItem = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const getConfigEffects = async (sceneOption: any, index: number) => {
    setIsEffectLoading(true);
    setSelectedEffectIndex(index);
    let intro = data.scenes[selectedSceneIndex].intro || false;
    let outro = data.scenes[selectedSceneIndex].outro || false;
    const effectsResponse = await axios.get(`${BASE_API_URL}/choose-scene-effects/${sceneOption.id}?scene_sequence_id=${sceneOption.scene_sequence_id}&intro=${intro}&outro=${outro}&orientation=landscape`);
    setIsEffectLoading(false);
    try {
      setConfigEffects(effectsResponse.data.data);
    } catch (error) {
      setConfigEffects([]);
    }
  }

  const handleChooseEffectSelection = async (effect) => {
    var updated_request_body = [...requestBody];
    const sceneOptionIds = updated_request_body[selectedSceneIndex].sceneConfigs[selectedEffectIndex].scene_options_ids;
    const newSceneOptionIds: any = [];
    sceneOptionIds.forEach(scnOptIds => {
      if (scnOptIds === updated_request_body[selectedSceneIndex].sceneConfigs[selectedEffectIndex].enterprise_scene_id) {
        newSceneOptionIds.push(effect.id);
      }
      else {
        sceneOptionIds.push(scnOptIds);
      }
    });
    updated_request_body[selectedSceneIndex].sceneConfigs[selectedEffectIndex].enterprise_scene_id = effect.id;
    updated_request_body[selectedSceneIndex].sceneConfigs[selectedEffectIndex].scene_options_ids = newSceneOptionIds;
    updated_request_body[selectedSceneIndex].sceneConfigs[selectedEffectIndex].segment_id = effect.scene_info.segment_id;

    const sceneType = updated_request_body[selectedSceneIndex].sceneConfigs[selectedEffectIndex].scene_type;
    if (sceneType === "intro" || sceneType === "outro") {
      const previousIntroOutroData = updated_request_body[selectedSceneIndex].sceneConfigs[0];
      const updated_scene_options = [...data.scenes];
      updated_scene_options[selectedSceneIndex].scene_options[0] = effect;
      updated_scene_options[selectedSceneIndex].scene_options[0].requirement = effect.scene_info.requirement;
      updated_scene_options[selectedSceneIndex].scene_options[0].segment_id = effect.scene_info.segment_id;
      setData({ ...data, scenes: updated_scene_options });
      updated_request_body[selectedSceneIndex].sceneConfigs[0] = setSceneObject(updated_scene_options[selectedSceneIndex], 0, false);
    }

    setRequestBody(updated_request_body);



  }

  return (
    <>
      {data && (
        <div className="scenes-container">
          <Accordion
            style={{ marginBottom: "30px" }}
            className={`accordion-container ${coachMarkStepIndex == 0 && showCoachMark ? "active-step" : ""}`}
            activeKey={activeKey}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={() => toggleAccordionItem("0")}>
                <div className="input-accordion-header">
                  <span
                    className={`title-label no-collapse  ${coachMarkStepIndex == 0 && showCoachMark ? "active-header" : ""
                      }`}
                  >
                    Input
                  </span>
                  <span className="title-input-url">
                    {localStorage.getItem('documentName')}
                    {/* {!isCopied && (
                      <img
                        style={{ width: "20px", marginLeft: "10px" }}
                        src="assets/copy-clipboard.png"
                        alt=""
                        onClick={copyClipboard}
                      />
                    )}
                    {isCopied && <span style={{ fontSize: "16px", color: "green", marginLeft: "10px" }}>Copied</span>} */}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="input-form-group">
                  <div className="row mb-3 ml-1 input-row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="accordion-form-group">
                        <span className="generate-form-label">Name</span>
                        <input
                          type="text"
                          onChange={onNameChange}
                          value={videoName}
                          className="generate-form-input"
                          style={{ backgroundColor: "white", border: "1px solid rgba(24,24,24, 0.7)" }}
                          placeholder="Enter video name"
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="accordion-form-group">
                        <span className="generate-form-label">Language</span>
                        <CustomSelect
                          data={languages}
                          value={selectedLanguage}
                          options={languages}
                          hasAudio={false}
                          onChange={handleLanguageChange}
                          placeholder="English"
                        ></CustomSelect>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 ml-1 input-row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="accordion-form-group">
                        <span className="generate-form-label">Voice</span>
                        <CustomSelect
                          data={voices}
                          value={selectedVoice.label}
                          options={voices.map((x) => {
                            return x.label;
                          })}
                          hasAudio={true}
                          onChange={handleVoiceChange}
                          placeholder="Select Voice"
                        ></CustomSelect>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="accordion-form-group">
                        <span className="generate-form-label">Music</span>
                        <CustomSelect
                          data={musics}
                          value={selectedMusic.label}
                          options={musics.map((x) => {
                            return x.label;
                          })}
                          hasAudio={true}
                          onChange={handleMusicChange}
                          placeholder="Select Music"
                        ></CustomSelect>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 ml-1  input-row">
                    {selectedBackgroundVideo && (
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="accordion-form-group">
                          <span className="generate-form-label">Background</span>
                          <div className="background-form-content">
                            <div className="selected-video-wrapper">
                              <video
                                className="selected-video"
                                key={selectedBackgroundVideo}
                                onClick={() => {
                                  handleSeeExampleClick(selectedBackgroundVideo);
                                }}
                              >
                                <source src={selectedBackgroundVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            <button
                              className="form-button"
                              style={{ marginLeft: "0" }}
                              onClick={() => onBackgroundVideoChange(false)}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="accordion-form-group">
                        <span className="generate-form-label">Caption</span>
                        <div className="background-form-content">
                          <label className="toggle-switch" style={{ alignSelf: "baseline" }}>
                            <input
                              type="checkbox"
                              style={{ display: 'none' }}
                              id="video-caption"
                              checked={captionEnabled}
                              onChange={onCaptionChange}
                            />
                            <span className="switch" />
                          </label>
                          {captionEnabled && (
                            <>
                              <div className="selected-video-wrapper">
                                <video
                                  className="selected-video"
                                  key={selectedCaption}
                                  onClick={() => {
                                    handleSeeExampleClick(selectedCaption);
                                  }}
                                >
                                  {
                                    !selectedCaption &&
                                    <source src={enterpriseVideoCaptions[0].preview_url} type="video/mp4" />
                                  }
                                  {
                                    selectedCaption &&
                                    <source src={selectedCaption} type="video/mp4" />
                                  }
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                              <button
                                className="form-button"
                                style={{ marginLeft: "0" }}
                                onClick={() => onBackgroundVideoChange(true)}
                              >
                                Change
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <Modal
                      className="background-video-modal"
                      size="lg"
                      show={backgroundVideoOpen}
                      onHide={handleBackgroundClose}
                      centered
                    >
                      <Modal.Header className="background-video-header" closeButton>
                        <div className="background-video-title">
                          Select {isBackgroundSelected ? "Background Video" : "Video Caption"}
                        </div>
                      </Modal.Header>
                      <Modal.Body className="background-video-modal-body">
                        <div className={`video-container ${backgroundVideos?.length > 4 ? "allow-scroll" : ""}`}>
                          {isBackgroundSelected &&
                            backgroundVideos &&
                            backgroundVideos.map((video, index) => (
                              <div key={video.segment_id} className="video-item">
                                <Form.Check
                                  className="change-check"
                                  checked={selectedVideoIndex === index}
                                  type="checkbox"
                                  color="rgb(255, 73, 64)"
                                  onChange={() => {
                                    setSelectedVideoIndex(index);
                                  }}
                                />
                                <div className="video-content">
                                  <div className="video-wrapper">
                                    <video
                                      className="showing-video"
                                      onClick={() => {
                                        setSelectedVideoIndex(index);
                                        handleSeeExampleClick(
                                          backgroundVideos[index].asset_url.landscape,
                                          "video",
                                          false,
                                          true
                                        );
                                      }}
                                    >
                                      <source src={video.asset_url.landscape} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  </div>
                                  <p className="video-label">Videos-{index + 1}</p>
                                </div>
                              </div>
                            ))}
                          {!isBackgroundSelected &&
                            enterpriseVideoCaptions.map((video, index) => (
                              <div key={video.id} className="video-item">
                                <Form.Check
                                  className="change-check"
                                  checked={selectedVideoIndex === index}
                                  type="checkbox"
                                  color="rgb(255, 73, 64)"
                                  onChange={() => {
                                    setSelectedVideoIndex(index);
                                  }}
                                />
                                <div className="video-content">
                                  <div className="video-wrapper">
                                    <video
                                      className="showing-video"
                                      onClick={() => {
                                        setSelectedVideoIndex(index);
                                        handleSeeExampleClick(video.preview_url, "video", false, true);
                                      }}
                                    >
                                      <source src={video.preview_url} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  </div>
                                  <p className="video-label">Videos-{index + 1}</p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="action-container">
                          <button className="change-save-button" onClick={() => handleVideoSelect(selectedVideoIndex)}>
                            Select
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="accordion-form-group disabled-form-group">
                        <span className="generate-form-label">Duration</span>
                        <div className="slider-container">
                          <div className="slider-labels">
                            <span className="duration-start-end-label">00:30</span>
                            <span className="min-max-values-label">
                              <b>Min</b> : {formatDuration(duration[0])} - <b> Max</b> : {formatDuration(duration[1])}
                            </span>
                            <span className="duration-start-end-label">02:30</span>
                          </div>
                          <Range
                            values={duration}
                            step={STEP}
                            min={MIN}
                            max={MAX}
                            onChange={handleSliderChange}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "3px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: calculateGradient(duration, MIN, MAX),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, index }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                  border: "none",
                                  backgroundColor: index === 0 ? "#00B7EA" : "#FF4940",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "0px 2px 6px #AAA",
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div
            style={{ marginBottom: "50px" }}
            className={`scenes-section  ${coachMarkStepIndex == 1 && showCoachMark ? "active-step" : ""}`}
          >
            <h4 className={`title-label  ${coachMarkStepIndex == 1 && showCoachMark ? "active-header" : ""}`}>
              Scenes
            </h4>
            <div className="scenes-selector-container">
              <div className="scenes-wrapper-wrapper">
                <button className="nav-arrow left-arrow" onClick={handlePrevClick}>
                  <img
                    style={{ width: "11px", opacity: `${selectedSceneIndex === 0 ? 0.3 : 1}` }}
                    src="assets/left-arrow.png"
                    alt=""
                  />
                </button>
                <div className="scenes-wrapper">
                  <div className="button-container">
                    {data.scenes.map((_, index) => (
                      <div className="scenes-button-wrapper" key={index}>
                        {editScenes && (
                          <div className="reorder-scenes">
                            <img
                              style={{ width: "11px", cursor: 'pointer', marginLeft: '2px', opacity: `${index === 0 ? 0.5 : 1}` }}
                              src="assets/red-back.png"
                              alt=""
                              onClick={() => {
                                if (index > 0) {
                                  setRollbackSceneData(data.scenes);
                                  const newScenes = [...data.scenes];

                                  [newScenes[index - 1], newScenes[index]] = [newScenes[index], newScenes[index - 1]];
                                  setData({ ...data, scenes: newScenes });
                                  const newReqBodyScenes = [...requestBody];
                                  [newReqBodyScenes[index - 1], newReqBodyScenes[index]] = [newReqBodyScenes[index], newReqBodyScenes[index - 1]];
                                  setRequestBody(newReqBodyScenes);
                                }
                              }}
                            />

                            <img
                              style={{ width: "11px", marginLeft: '10px', cursor: 'pointer', opacity: `${+localStorage.getItem('sceneLength') - 1 === index ? 0.5 : 1}` }}
                              src="assets/blue-right.png"
                              alt=""
                              onClick={() => {
                                if (index < data.scenes.length - 1) {
                                  setRollbackSceneData(data.scenes);
                                  const newScenes = [...data.scenes];
                                  [newScenes[index + 1], newScenes[index]] = [newScenes[index], newScenes[index + 1]];
                                  setData({ ...data, scenes: newScenes });
                                  const newReqBodyScenes = [...requestBody];
                                  [newReqBodyScenes[index + 1], newReqBodyScenes[index]] = [newReqBodyScenes[index], newReqBodyScenes[index + 1]];
                                  setRequestBody(newReqBodyScenes);
                                }
                              }}
                            />
                          </div>
                        )}

                        {/* Scene Button */}
                        <button
                          className={`scene-btn ${selectedSceneIndex === index ? "selected" : ""} ${_.intro || _.outro ? "intro" : ""}`}
                          onClick={() => {
                            if (!editScenes) {
                              setShowErrorSection(false);
                              handleSceneClick(index)
                            }
                          }}
                          disabled={globalLoading}
                        >

                          {_.intro ? " Intro " : _.outro ? "Outro" : _.id - 1}

                          {editScenes && (
                            <img
                              className="delete-scene-btn"
                              src="assets/scene-delete.png"
                              alt=""
                              onClick={() => {
                                localStorage.setItem("currentDeletingSceneId", index);
                                setShowDeleteSceneModal(true);
                              }}
                            />
                          )}
                        </button>
                      </div>
                    ))}

                    {/* Add new scene button */}
                    {editScenes && (
                      <div className="scenes-button-wrapper" style={{ marginTop: '24px' }}>
                        <button
                          className="scene-btn"
                          onClick={() => {
                            const emptyScene = { ...data.default_empty_scene[0] };
                            const emptySceneOptions = emptyScene.scene_options.filter(x => !x.scene_sequence_id.includes('recording')).filter(x => !x.scene_sequence_id.includes('video_upload')).map((x) => {
                              return {
                                ...x,
                                data: {
                                  videos: {
                                    available_videos: [],
                                    uploaded_videos: []
                                  },
                                  images: {
                                    available_images: [],
                                    uploaded_images: []
                                  },
                                  searchPhrases: []
                                }
                              }
                            });
                            emptyScene.scene_options = emptySceneOptions;
                            emptyScene.id = data.scenes.length + 1;
                            const newScenes = [...data.scenes, emptyScene];
                            const uptData = { ...data };
                            uptData.scenes = newScenes;
                            setData(uptData);
                            const uptReqBody = requestBody.map(x => x);
                            const scnConfig = [];
                            emptyScene.scene_options.forEach((_, i) => {
                              scnConfig.push(setSceneObject(emptyScene, i, true));
                            });
                            uptReqBody.push({
                              sceneIndex: emptyScene.id,
                              sceneConfigs: scnConfig
                            });
                            setRequestBody(uptReqBody);
                          }}
                          disabled={globalLoading}
                        >
                          +
                        </button>
                      </div>
                    )}

                    <button className="edit-scenes-btn" onClick={() => {
                      const sceneIds = data.scenes.map(scene => scene.id);
                      const isOrdered = sceneIds.every((id, index) => id === index + 1);
                      if (!isOrdered) {
                        setShowReOrderSceneModal(true);
                      }
                      setEditScenes(!editScenes)
                    }
                    }

                    >
                      {
                        editScenes ? 'Done' : 'Edit'
                      }
                    </button>

                  </div>
                </div>

                <button className="nav-arrow right-arrow" onClick={handleNextClick}>
                  <img
                    style={{ width: "11px", opacity: `${+localStorage.getItem('sceneLength') - 1 === selectedSceneIndex ? 0.3 : 1}` }}
                    src="assets/right-arrow.png"
                    alt=""
                  />
                </button>
              </div>
            </div>

            <Modal show={showDeleteSceneModal} onHide={() => { setShowDeleteSceneModal(false) }} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="text-wrapper" style={{ textAlign: "center", width: "100%" }}>
                  <span>
                    {

                      data.scenes.length <= 1 ?
                        "Atleast one scene should be present."
                        :
                        "Are you sure you want to delete this scene?"

                    }
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="action-container">
                  <button
                    className="change-save-button cancel-button"
                    onClick={() => {
                      setShowDeleteSceneModal(false);
                    }}
                  >

                    {" "}
                    {
                      data.scenes.length <= 1 ? "OK"
                        : "Cancel"
                    }
                    {" "}
                  </button>
                  {
                    data.scenes.length > 1 &&
                    <button className="change-save-button" onClick={() => {
                      if (data.scenes.length > 1) {
                        const newScenes = [...data.scenes];
                        const currentDeletingSceneId = +localStorage.getItem("currentDeletingSceneId");
                        if (selectedSceneIndex > 0) {
                          setSelectedSceneIndex(selectedSceneIndex - 1);
                        } else {
                          setSelectedSceneIndex(0);
                        }

                        newScenes.splice(currentDeletingSceneId, 1);

                        const reorderedScenes = newScenes.map((scene, index) => ({
                          ...scene,
                          id: index + 1,
                        }));

                        setData({ ...data, scenes: reorderedScenes });

                        const updatedReqBody = [...requestBody];
                        updatedReqBody.splice(currentDeletingSceneId, 1);

                        const updatedRequestBody = updatedReqBody.map((item, index) => ({
                          ...item,
                          sceneIndex: index + 1,
                          sceneConfigs: item.sceneConfigs.map((config) => ({
                            ...config,
                            id: index + 1,
                          })),
                        }));

                        setRequestBody(updatedRequestBody);
                        setShowDeleteSceneModal(false);
                      }
                      else {
                        console.warn('Invalid index or no more scenes to delete.');
                      }
                    }}>

                      {" "}
                      OK{" "}
                    </button>
                  }

                </div>
              </Modal.Footer>
            </Modal>

            <Modal show={showReOrderSceneModal} onHide={() => { setShowReOrderSceneModal(false) }} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="text-wrapper" style={{ textAlign: "center", width: "100%" }}>
                  <span>
                    Your new arrangement will be renumbered in ascending order.
                  </span>
                </div>
                <div className="button-container" style={{ padding: '10px' }}>
                  {data.scenes.map((_, index) => (
                    <div className="scenes-button-wrapper" key={index}>
                      <button
                        className={`scene-btn`}
                      >
                        {
                          _.scene_type === "intro" || _.scene_type === "outro" ?

                            _.scene_type : index + 1
                        }

                        {_.scene_type}
                      </button>
                    </div>
                  ))}
                </div>

                <div className="text-wrapper" style={{ textAlign: "center", width: "100%" }}>
                  <span>
                    Do you wish to continue ?
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="action-container">
                  <button
                    className="change-save-button cancel-button"
                    onClick={() => {
                      setData({ ...data, scenes: rollbackSceneData });
                      setShowReOrderSceneModal(false);
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  <button className="change-save-button" onClick={() => {
                    const updatedScenes = data.scenes.map((scene, index) => ({
                      ...scene,
                      id: index + 1,
                    }));

                    setData({ ...data, scenes: updatedScenes });

                    const updatedRequestBody = requestBody.map((item, index) => {
                      const correspondingScene = data.scenes[index];
                      const hasOriginalId = correspondingScene.original_id !== undefined;

                      return {
                        ...item,
                        sceneIndex: index + 1,
                        sceneConfigs: item.sceneConfigs.map((config) => ({
                          ...config,
                          id: index + 1,
                          original_id: hasOriginalId ? correspondingScene.original_id : config.original_id,
                        })),
                      };
                    });

                    setRequestBody(updatedRequestBody);


                    setRequestBody(updatedRequestBody);
                    setShowReOrderSceneModal(false);
                  }}>

                    {" "}
                    OK{" "}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>

          </div>
          {(showScriptInput || requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].is_additional_scene) && !showCoachMark ? (
            <div style={{ marginBottom: "50px" }} className="script-section">
              <h4 className="title-label">Script</h4>
              <div className="script-container">
                <textarea
                  className="script-input"
                  placeholder="Enter script here (not more than 150 characters)"
                  maxLength={maxValue}
                  disabled={!isEditing || globalLoading}
                  value={requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].voiceOver?.text}
                  onChange={handleInputChange}
                />
                <div className="script-footer">
                  {isEditing && (
                    <button className="done-btn" onClick={handleProcessing}>
                      Done
                    </button>
                  )}
                  {isProcessing && <button className="done-btn">Processing</button>}
                  {!isEditing && !isProcessing && (
                    <>
                      <button className="done-btn" onClick={handleEditChange}>
                        Edit
                      </button>
                      {isPlaying ? (
                        <span className="stop-button" onClick={handleStop}></span>
                      ) : (
                        <span className="play-button" onClick={handlePlay}></span>
                      )}
                    </>
                  )}
                  <span className="char-count">
                    {requestBody[selectedSceneIndex].sceneConfigs[getConfigurationIndex()].voiceOver?.text?.length}/
                    {maxValue}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Accordion
            style={{ marginBottom: "50px" }}
            className={`accordion-container ${coachMarkStepIndex == 2 && showCoachMark ? "active-step" : ""}`}
            defaultActiveKey="0"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header className="content-selection-accordion-header">
                <div className="input-accordion-header">
                  <h4 className={`title-label  ${coachMarkStepIndex == 2 && showCoachMark ? "active-header" : ""}`}>
                    Content Configurations
                  </h4>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                <Swiper
                  ref={swiperRef}
                  modules={[Navigation]}
                  spaceBetween={40}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.navigation.nextEl = navigationNextRef.current;
                    swiper.navigation.prevEl = navigationPrevRef.current;
                  }}
                  slidesPerView={3}
                >
                  {data.scenes[selectedSceneIndex].scene_options.map((config, index) => (
                    <SwiperSlide key={index}>
                      <div className="configuration-content">
                        <div className="checkbox-content">
                          <Form.Check
                            type="checkbox"
                            color="rgb(255, 73, 64)"
                            checked={requestBody[selectedSceneIndex]?.sceneConfigs[index]?.isSelected}
                            onChange={(event) => handleCheckboxChange(index, event)}
                          />
                        </div>
                        <div
                          className={`content-options ${requestBody[selectedSceneIndex]?.sceneConfigs.indexOf(
                            requestBody[selectedSceneIndex].sceneConfigs.filter((x) => x.isSelected)[0]
                          ) === index
                            ? "selected"
                            : ""
                            }`}
                        >
                          <ul className="content-item-container">
                            {getMediaSequence(config.scene_sequence_id).map((sequence) => {
                              if (sequence.type === "video" && config.requirement.video) {
                                return (
                                  <li className="content-item">
                                    <img src="assets/video-icon.png" alt="" />
                                    {config.requirement.video.requiredCount === 1 ? (
                                      <span>{config.requirement.video.requiredCount} Video</span>
                                    ) : (
                                      <span>{config.requirement.video.requiredCount} Videos</span>
                                    )}
                                  </li>
                                );
                              } else if (sequence.type === "image" && config.requirement.image) {
                                return (
                                  <li className="content-item">
                                    <img src="assets/image-icon.png" alt="" />
                                    {config.requirement.image.requiredCount === 1 ? (
                                      <span>{config.requirement.image.requiredCount} Image</span>
                                    ) : (
                                      <span>{config.requirement.image.requiredCount} Images</span>
                                    )}
                                  </li>
                                );
                              } else if (sequence.type === "text" && config.requirement.text) {
                                return (
                                  <li className="content-item">
                                    <img src="assets/text-icon.png" alt="" />
                                    {config.requirement.text.length === 1 ? (
                                      <span>{config.requirement.text.length} Text</span>
                                    ) : (
                                      <span>{config.requirement.text.length} Texts</span>
                                    )}
                                  </li>
                                );
                              }
                              else if (sequence.type === "thank_you" && config.requirement.thank_you) {
                                return (
                                  <li className="content-item">
                                    <img src="assets/video-icon.png" alt="" />
                                    {config.requirement.thank_you.requiredCount === 1 ? (
                                      <span>{config.requirement.thank_you.requiredCount} Thank You</span>
                                    ) : (
                                      <span>{config.requirement.thank_you.requiredCount} Thank You</span>
                                    )}
                                  </li>
                                );
                              }
                              else if (sequence.type === "video_upload" && config.requirement.video_upload) {
                                return (
                                  <li className="content-item">
                                    <img src="assets/video-icon.png" alt="" />
                                    {config.requirement.video_upload.requiredCount === 1 ? (
                                      <span>{config.requirement.video_upload.requiredCount} Video Upload</span>
                                    ) : (
                                      <span>{config.requirement.video_upload.requiredCount} Videos Upload</span>
                                    )}
                                  </li>
                                );
                              }
                              else {
                                return null;
                              }
                            })}
                          </ul>

                          {/* Check if ANY sequence has type "video_upload" and if config has video_upload requirement */}
                          {!(getMediaSequence(config.scene_sequence_id).some(sequence =>
                            sequence.type === "video_upload") && config.requirement.video_upload) && (
                              <div className="content-action">
                                <button
                                  className="content-action-button"
                                  onClick={() => {
                                    getConfigEffects(data.scenes[selectedSceneIndex].scene_options[index], index);
                                    setShowChooseEffectModal(true)
                                  }}
                                >
                                  Choose Effect
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <Modal
                  className="background-video-modal"
                  size="lg"
                  show={showChooseEffectModal}
                  onHide={() => { setShowChooseEffectModal(false) }}
                  centered
                >
                  <Modal.Header className="background-video-header" closeButton>
                    <div className="background-video-title">
                      Choose Effect
                    </div>
                  </Modal.Header>
                  <Modal.Body className="background-video-modal-body">
                    <div className={`video-container-effects allow-scroll`}>
                      {!isEffectLoading && configEffects.map((effect, index) => (
                        <div key={effect.id} className="video-item">
                          <Form.Check
                            className="change-check"
                            checked={requestBody[selectedSceneIndex].sceneConfigs[selectedEffectIndex].enterprise_scene_id === effect.id}
                            type="checkbox"
                            color="rgb(255, 73, 64)"
                            onChange={() => {
                              handleChooseEffectSelection(effect);
                            }}
                          />
                          <div className="video-content">
                            <div className="video-wrapper">
                              <video
                                className="showing-video"
                                onClick={() => {
                                  setSelectedEffect(effect);
                                  setSelectedEffectVideoUrl(effect.sample_output);
                                  setTimeout(() => {
                                    console.log("Effect video is loading");
                                  }, 500);
                                  setIsEffectVideoDialogOpen(true);

                                }}
                              >
                                <source src={effect.sample_output} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            <p className="video-label">Effect-{index + 1}</p>
                          </div>
                        </div>
                      ))}
                      {
                        isEffectLoading &&
                        <div className="loading-container">
                          <span>Loading ...</span>
                        </div>
                      }
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="action-container">
                      <button className="change-save-button" onClick={() => setShowChooseEffectModal(false)}>
                        Done
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>

                <Modal show={isEffectVideoDialogOpen} onHide={() => { setIsEffectVideoDialogOpen(false) }} centered>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <VideoJS options={videoJsOptionsEffect} onReady={handlePlayerReady} />
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="action-container">
                      <button className="change-save-button" onClick={() => { handleChooseEffectSelection(selectedEffect); setIsEffectVideoDialogOpen(false); }}>
                        {" "}
                        Select{" "}
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
                <button className="navigation-button button-next" ref={navigationNextRef}>
                  <img src="assets/next-arrow.png" alt="" />
                </button>
                <button className="navigation-button button-prev" ref={navigationPrevRef}>
                  <img src="assets/prev-button.png" alt="" />
                </button>
              </Accordion.Body>
            </Accordion.Item>
            <Modal show={dialogOpen} onHide={handleCloseDialog} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                {videoUrl && <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />}
                {imageUrl && <img src={imageUrl} style={{ width: "100%" }} />}
              </Modal.Body>
              <Modal.Footer>
                <div className="action-container">
                  {isFromChangeModal && imageUrl && (
                    <button className="change-save-button" onClick={saveChangedImage}>
                      {" "}
                      Select{" "}
                    </button>
                  )}
                  {isFromChangeModal && videoUrl && (
                    <button className="change-save-button" onClick={saveChangedVideo}>
                      {" "}
                      Select{" "}
                    </button>
                  )}
                  {isFromInputSection && (
                    <button className="change-save-button" onClick={saveChangedVideo}>
                      {" "}
                      Select{" "}
                    </button>
                  )}
                </div>
              </Modal.Footer>
            </Modal>
          </Accordion>
          <Accordion
            className={`accordion-container no-z-index ${coachMarkStepIndex == 3 && showCoachMark ? "active-step pointer-events" : ""
              }`}
            defaultActiveKey={`${coachMarkStepIndex == 3 && showCoachMark ? "1" : "0"}`}
          >
            <Accordion.Item eventKey={`${coachMarkStepIndex == 3 && showCoachMark ? "1" : "0"}`}>
              <Accordion.Header className="content-selection-accordion-header">
                <div className="input-accordion-header">
                  <h4 className={`title-label  ${coachMarkStepIndex == 3 && showCoachMark ? "active-header" : ""}`}>
                    Content Selection
                  </h4>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion-body-content-selection">
                {/* {showCoachMark && coachMarkStepIndex == 3 && (
                  <div className={`coach-mark-wrapper step-${coachMarkStepIndex}`}>
                    <img className="coach-mark-arrow" src="assets/coach-mark-arrow.png" alt="" />
                    <div className="coach-mark-content">
                      <p>{coachMarkSteps[coachMarkStepIndex]}</p>
                      <button className="coach-mark-btn" onClick={setCoachMarkStep}>
                        Ok
                      </button>
                    </div>
                  </div>
                )} */}
                <div className="selection-item-container">
                  <Modal size="lg" show={changeDialogOpen} onHide={handleChangeCloseDialog} centered>
                    <Modal.Header closeButton>
                      <div className="change-header">
                        {(!fromLogo ||
                          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images
                            ?.uploaded_images?.length > 0 ||
                          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images
                            ?.available_images?.length > 0) && !isVideo && (
                            <button
                              className={`${availablePageModal ? "active-page" : ""} change-header-action`}
                              onClick={() => {
                                setAvailablePageModal(true);
                              }}
                            >
                              Available Images
                            </button>
                          )}
                        {(
                          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos
                            ?.uploaded_videos?.length > 0 ||
                          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos
                            ?.available_videos?.length > 0) && isVideo && (
                            <button
                              className={`${availablePageModal ? "active-page" : ""} change-header-action`}
                              onClick={() => {
                                setAvailablePageModal(true);
                              }}
                            >
                              Available Videos
                            </button>
                          )}
                        <button
                          className={`${!availablePageModal ? "active-page" : ""} change-header-action`}
                          onClick={() => {
                            setAvailablePageModal(false);
                            handleSearchPhrases();
                          }}
                        >
                          Search
                        </button>
                        <button
                          className="change-header-action"
                          onClick={() => {
                            setShowUpload(true);
                            setAvailablePageModal(false);
                            setChangeDialogOpen(false);
                          }}
                        >
                          Upload
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="change-body">
                        {availablePageModal && !isVideo && (
                          <div className={`change-content`}>
                            {data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images
                              ?.uploaded_images?.length > 0 && (
                                <>
                                  <span className="change-text">Images from your input</span>
                                  <div
                                    className={`change-item-content ${data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images
                                      ?.uploaded_images?.length <= 3
                                      ? "no-height"
                                      : "justify-content-center"
                                      }`}
                                  >
                                    {data.scenes[selectedSceneIndex].scene_options[
                                      getConfigurationIndex()
                                    ].data?.images?.uploaded_images?.map((image, index) => (
                                      <div className="change-item">
                                        <Form.Check
                                          className="change-check"
                                          checked={changeSelectedItemInputIndex === index}
                                          type="checkbox"
                                          color="rgb(255, 73, 64)"
                                          onChange={() => {
                                            setChangeSelectedItemInputIndex(index);
                                            setChangeSelectedItemIndex(999999);
                                          }}
                                        />
                                        <img
                                          onClick={() => {
                                            setChangeSelectedItemInputIndex(index);
                                            setChangeSelectedItemIndex(999999);
                                            handleSeeExampleClick(image.src.original, "image", true);
                                          }}
                                          className={`change-image ${changeSelectedItemInputIndex === index ? "selected" : ""
                                            }`}
                                          src={image.src.original}
                                          alt=""
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            {data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images
                              ?.available_images?.length > 0 && (
                                <>
                                  <span className="change-text">Stock images</span>
                                  <div
                                    className={
                                      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.images
                                        ?.available_images?.length > 3
                                        ? "change-item-content allow-scroll justify-content-center"
                                        : "change-item-content"
                                    }
                                  >
                                    {data.scenes[selectedSceneIndex].scene_options[
                                      getConfigurationIndex()
                                    ].data?.images?.available_images?.map((image, index) => (
                                      <div className="change-item">
                                        <Form.Check
                                          className="change-check"
                                          checked={changeSelectedItemIndex === index}
                                          type="checkbox"
                                          color="rgb(255, 73, 64)"
                                          onChange={() => {
                                            setChangeSelectedItemIndex(index);
                                            setChangeSelectedItemInputIndex(999999);
                                          }}
                                        />
                                        <img
                                          className={`change-image ${changeSelectedItemIndex === index ? "selected" : ""
                                            }`}
                                          onClick={() => {
                                            setChangeSelectedItemIndex(index);
                                            setChangeSelectedItemInputIndex(999999);
                                            handleSeeExampleClick(image.src.original, "image", true);
                                          }}
                                          src={image.src.original}
                                          alt=""
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                          </div>
                        )}
                        {availablePageModal && isVideo && (
                          <div className="change-content">
                            {data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos
                              ?.uploaded_videos?.length > 0 && (
                                <>
                                  <span className="change-text">Videos from your input</span>
                                  <div
                                    className={`change-item-content ${data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos
                                      ?.uploaded_videos?.length <= 3
                                      ? "no-height"
                                      : "justify-content-center"
                                      }`}
                                  >
                                    {data.scenes[selectedSceneIndex].scene_options[
                                      getConfigurationIndex()
                                    ].data?.videos?.uploaded_videos?.map((video, index) => (
                                      <div className="change-item">
                                        <Form.Check
                                          className="change-check"
                                          checked={changeSelectedItemInputIndex === index}
                                          type="checkbox"
                                          color="rgb(255, 73, 64)"
                                          onChange={() => {
                                            setChangeSelectedItemInputIndex(index);
                                            setChangeSelectedItemIndex(99999);
                                          }}
                                        />
                                        <video
                                          className={`change-image`}
                                          onClick={() => {
                                            setChangeSelectedItemInputIndex(index);
                                            setChangeSelectedItemIndex(99999);
                                            handleSeeExampleClick(video.previewUrl, "video", true);
                                          }}
                                          src={video.thumbnailUrl}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            {data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos
                              ?.available_videos.length > 0 && (
                                <>
                                  <span className="change-text">Stock Videos</span>
                                  <div
                                    className={
                                      data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data?.videos
                                        ?.available_videos?.length > 0
                                        ? `change-item-content allow-scroll ${pexelImagesResponse.length >= 3 ? "justify-content-center" : ""
                                        }`
                                        : "change-item-content"
                                    }
                                  >
                                    {data.scenes[selectedSceneIndex].scene_options[
                                      getConfigurationIndex()
                                    ].data?.videos?.available_videos?.map((video, index) => (
                                      <div className="change-item">
                                        <Form.Check
                                          className="change-check"
                                          checked={changeSelectedItemIndex === index}
                                          type="checkbox"
                                          color="rgb(255, 73, 64)"
                                          onChange={() => {
                                            setChangeSelectedItemIndex(index);
                                            setChangeSelectedItemInputIndex(999999);
                                          }}
                                        />
                                        <div style={{ position: "relative" }}>
                                          <img
                                            className={`change-image`}
                                            onClick={() => {
                                              setChangeSelectedItemIndex(index);
                                              setChangeSelectedItemInputIndex(999999);
                                              handleSeeExampleClick(video.previewUrl, "video", true);
                                            }}
                                            src={video.thumbnailUrl}
                                          />
                                          {(video.video_duration_in_seconds && video.video_duration_in_seconds !== 0) ? (
                                            <span className="duration-container">
                                              {getDuration(video.video_duration_in_seconds)}
                                            </span>
                                          ) : <></>}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                          </div>
                        )}
                        {isLoading && !availablePageModal && (
                          <div className="loading-container">
                            <span>Loading ...</span>
                          </div>
                        )}
                        {!availablePageModal && !isVideo && (
                          <div className="change-content">
                            <span className="search-text">
                              Search the internet for royalty free stock content using your own keywords
                            </span>
                            <div className="search-bar-container">
                              <div className="input-container">
                                <input
                                  type="text"
                                  id="pexel-input"
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchPexelImages("image");
                                    }
                                  }}
                                ></input>
                                <button
                                  className="search-button"
                                  onClick={() => {
                                    searchPexelImages("image");
                                  }}
                                >
                                  <img src="assets/pexels-search.png" />
                                </button>
                              </div>
                              <span className="pexels-container">
                                Stock images by <img src="assets/pexels.png" />
                              </span>
                            </div>
                            <div className="badge-container">
                              {searchPhrases.length > 0 &&
                                searchPhrases.map((sp) => {
                                  return (
                                    <>
                                      <Badge
                                        bg="#e7e7e7"
                                        className="custom-badge"
                                        style={{ backgroundColor: "#e7e7e7 !important", cursor: "pointer" }}
                                        onClick={() => {
                                          (document.getElementById("pexel-input") as HTMLInputElement).value = sp;
                                          searchPexelImages("image");
                                        }}
                                      >
                                        {sp}
                                        <button onClick={() => setSearchPhrases(searchPhrases.filter((x) => x !== sp))}>
                                          x
                                        </button>
                                      </Badge>
                                    </>
                                  );
                                })}
                            </div>

                            <div
                              className={
                                pexelImagesResponse.length > 0
                                  ? `change-item-content allow-scroll ${pexelImagesResponse.length >= 3 ? "justify-content-center" : ""
                                  }`
                                  : "change-item-content"
                              }
                            >
                              {pexelImagesResponse?.map((image, index) => (
                                <div className="change-item">
                                  <Form.Check
                                    className="change-check"
                                    checked={changeSelectedItemIndex === index}
                                    type="checkbox"
                                    color="rgb(255, 73, 64)"
                                    onChange={() => {
                                      setChangeSelectedItemIndex(index);
                                    }}
                                  />
                                  <span className="span-selected">
                                    <div className={`img-wrapper`}>
                                      <img
                                        onClick={() => {
                                          setChangeSelectedItemIndex(index);
                                          handleSeeExampleClick(image.src.original, "image", true);
                                        }}
                                        className={`pexel-image`}
                                        src={image.src.original}
                                        alt=""
                                      />
                                    </div>
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {!availablePageModal && isVideo && (
                          <div className="change-content">
                            <span className="search-text">
                              Search the internet for royalty free stock content using your own keywords
                            </span>
                            <div className="search-bar-container">
                              <div className="input-container">
                                <input
                                  type="text"
                                  id="pexel-input"
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchPexelImages("video");
                                    }
                                  }}
                                ></input>
                                <button
                                  className="search-button"
                                  onClick={() => {
                                    searchPexelImages("video");
                                  }}
                                >
                                  <img src="assets/pexels-search.png" />
                                </button>
                              </div>
                            </div>
                            <div className="badge-container">
                              {searchPhrases.length > 0 &&
                                searchPhrases.map((sp) => {
                                  return (
                                    <>
                                      <Badge
                                        bg="#e7e7e7"
                                        className="custom-badge"
                                        style={{ backgroundColor: "#e7e7e7 !important", cursor: "pointer" }}
                                        onClick={() => {
                                          {
                                            (document.getElementById("pexel-input") as HTMLInputElement).value = sp;
                                            searchPexelImages("video");
                                          }
                                        }}
                                      >
                                        {sp}
                                        <button onClick={() => setSearchPhrases(searchPhrases.filter((x) => x !== sp))}>
                                          x
                                        </button>
                                      </Badge>
                                    </>
                                  );
                                })}
                            </div>

                            <div
                              className={
                                pexelImagesResponse.length > 0
                                  ? `change-item-content allow-scroll ${pexelImagesResponse.length >= 3 ? "justify-content-center" : ""
                                  }`
                                  : "change-item-content"
                              }
                            >
                              {pexelImagesResponse?.map((image, index) => (
                                <div className="change-item">
                                  <Form.Check
                                    className="change-check"
                                    checked={changeSelectedItemIndex === index}
                                    type="checkbox"
                                    color="rgb(255, 73, 64)"
                                    onChange={() => {
                                      setChangeSelectedItemIndex(index);
                                    }}
                                  />
                                  <span className="span-selected">
                                    <div className={`video-wrapper`}>
                                      <img
                                        className={`pexel-image`}
                                        src={image.stockItem.thumbnailUrl}
                                        alt=""
                                        onClick={() => {
                                          setChangeSelectedItemIndex(index);
                                          handleSeeExampleClick(image.stockItem.previewUrl, "video", true);
                                        }}
                                      />
                                      {(image.stockItem.duration && image.stockItem.duration !== 0) ? (
                                        <span className="duration-container">
                                          {getDuration(image.stockItem.duration)}
                                        </span>
                                      ) : <></>}
                                    </div>
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="change-footer">
                        {isVideo && (
                          <button
                            className="change-save-button"
                            disabled={
                              availablePageModal
                                ? !(changeSelectedItemIndex < 1000 || changeSelectedItemInputIndex < 1000)
                                : !(pexelImagesResponse.length > 0 && changeSelectedItemIndex < 1000)
                            }
                            onClick={saveChangedVideo}
                          >
                            {" "}
                            Save{" "}
                          </button>
                        )}
                        {!isVideo && (
                          <button
                            className="change-save-button"
                            disabled={
                              availablePageModal
                                ? !(changeSelectedItemIndex < 1000 || changeSelectedItemInputIndex < 1000)
                                : !(pexelImagesResponse.length > 0 && changeSelectedItemIndex < 1000)
                            }
                            onClick={saveChangedImage}
                          >
                            {" "}
                            Save{" "}
                          </button>
                        )}
                      </div>
                    </Modal.Footer>
                  </Modal>

                  {getMediaSequence(
                    data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].scene_sequence_id
                  ).map((sequence) => {
                    if (
                      sequence.type === "image" &&
                      requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0]?.images.length > 0
                    ) {
                      return (
                        <div className="selection-item">
                          <div className="selection-header">
                            <span>
                              <b>Images:</b>{" "}
                              {requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0]?.images
                                .length > 0
                                ? requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0]?.images
                                  .length + " required"
                                : ""}
                            </span>
                            { }
                            <span className="corner-class">{getAvailableImageCount()} available</span>
                          </div>

                          <div className="selection-content">
                            {requestBody[selectedSceneIndex]?.sceneConfigs
                              .filter((x) => x.isSelected)[0]
                              ?.images.map((image, index) =>
                                image.type == "logo" || image.type === "" ? (
                                  <div className="selection-content-item">
                                    {image.original.length <= 0 && (
                                      <>
                                        <div
                                          className="dummy-logo-container"
                                          onClick={() => {
                                            handleChangeClick(false, index, true);
                                          }}
                                        >
                                          <img
                                            width={40}
                                            height={40}
                                            src="assets/image-icon.png"
                                            alt="Logo Placeholder"
                                            className="logo-image"
                                          />
                                        </div>
                                        <button
                                          className="form-button no-margin"
                                          onClick={() => {
                                            handleChangeClick(false, index, true);
                                          }}
                                        >
                                          Upload
                                        </button>
                                      </>
                                    )}
                                    {image.original.length > 0 && (
                                      <>
                                        <div className={`second-img-wrapper`}>
                                          <img
                                            className="selection-form-image"
                                            src={image.original}
                                            alt="Logo Placeholder"
                                          />
                                        </div>
                                        <button
                                          className="form-button no-margin"
                                          onClick={() => handleChangeClick(false, index, false)}
                                        >
                                          Change
                                        </button>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="selection-content-item">
                                    <div className={`second-img-wrapper`}>
                                      <img
                                        src={image.original}
                                        className="selection-form-image"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleSeeExampleClick(image.original, "image");
                                        }}
                                        key={index}
                                      ></img>
                                    </div>
                                    <button
                                      className="form-button no-margin"
                                      onClick={() =>
                                        data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data
                                          ?.images?.uploaded_images?.length > 0 ||
                                          data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data
                                            ?.images?.available_images?.length > 0
                                          ? handleChangeClick(false, index, false)
                                          : handleChangeClick(false, index, true)
                                      }
                                    >
                                      {
                                        getAvailableImageCount() > 0 ? 'Change' : 'Upload'
                                      }
                                    </button>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      );
                    } else if (
                      sequence.type === "video" &&
                      requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0].videos.length > 0
                    ) {
                      return (
                        <div className="selection-item">
                          <div className="selection-header">
                            <span>
                              <b>Videos:</b>{" "}
                              {requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0].videos
                                .length > 0
                                ? requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0].videos
                                  .length + " required"
                                : ""}
                            </span>
                            <span className="corner-class">{getAvailableVideoCount()} available</span>
                          </div>
                          <div className="selection-content">
                            {requestBody[selectedSceneIndex]?.sceneConfigs
                              .filter((x) => x.isSelected)[0]
                              .videos.map((video, index) => (
                                <div className="selection-content-item">
                                  <div style={{ position: "relative" }}>
                                    {
                                      video.url.length > 0 ?
                                        <video
                                          id={`selection_video_${index}`}
                                          className={"selection-form-video"}
                                          onClick={() => {
                                            handleSeeExampleClick(video.url, "video");
                                          }}
                                          src={video.url}
                                        />
                                        :
                                        <div
                                          className="dummy-logo-container"
                                          onClick={() => {
                                            handleChangeClick(true, index, true);
                                          }}
                                        >
                                          <img
                                            width={60}
                                            height={60}
                                            src="assets/dummy-video.png"
                                            alt="Logo Placeholder"
                                            className="dummy-video-image"
                                          />
                                        </div>
                                    }
                                    {(video.duration && video.duration !== 0) ? (
                                      <span className="duration-container">{getDuration(video.duration)}</span>
                                    ) : <></>}
                                  </div>
                                  <button
                                    className="form-button no-margin"
                                    onClick={() => handleChangeClick(true, index, true)}
                                  >
                                    {
                                      getAvailableVideoCount() > 0 ? "Change" : "Upload"
                                    }
                                  </button>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    }
                    else if (
                      sequence.type === "text" &&
                      requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0]?.texts.length > 0
                    ) {
                      return (
                        <div className="selection-item">
                          <div className="selection-header">
                            <span>
                              <b>Text:</b>{" "}
                              {requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0]?.texts
                                .length > 0
                                ? requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0]?.texts
                                  .length + " required"
                                : ""}
                            </span>
                          </div>
                          <div className="selection-content-text">
                            {requestBody[selectedSceneIndex]?.sceneConfigs
                              .filter((x) => x.isSelected)[0]
                              ?.texts.map((textItem, index) => (
                                <div key={textItem.id} className="text-item">
                                  <input
                                    id={`textInput_` + index}
                                    type="text"
                                    value={textItem.value}
                                    style={{ border: "none", outline: "none" }}
                                    onChange={(e) => handleTextChange(index, e.target.value)}
                                    maxLength={textItem.characterLength}
                                    disabled={globalLoading}
                                    placeholder={textItem.placeholder.replace("Url", "URL")}
                                  />
                                  <span className="text-length">
                                    {textItem.value.length}/{textItem.characterLength}
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    }
                    else if (
                      sequence.type === "thank_you" && data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.thankYou
                    ) {
                      return (
                        <div className="selection-item">
                          <div className="selection-header">
                            <b>Thank You:</b>
                          </div>
                          <div className="selection-content">
                            <img
                              id={`selection_video_0`}
                              className={"selection-form-video"}
                              onClick={() => {
                                handleSeeExampleClick(data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.thankYou[0].url, "video");
                              }}
                              src={data.scenes[selectedSceneIndex].scene_options[getConfigurationIndex()].data.thankYou[0].thumbnail}
                            />
                          </div>
                        </div>
                      );
                    }
                    else if (
                      sequence.type === "video_upload"
                    ) {
                      return (
                        <div className="selection-item">
                          <div className="selection-header">
                            <b>Video Upload</b> :
                            {requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0].videos
                              .length > 0
                              ? requestBody[selectedSceneIndex]?.sceneConfigs.filter((x) => x.isSelected)[0].videos
                                .length + " required"
                              : ""}

                          </div>
                          <div className="selection-content">
                            {requestBody[selectedSceneIndex]?.sceneConfigs
                              .filter((x) => x.isSelected)[0]
                              .videos.map((video, index) => (
                                <div className="selection-content-item">
                                  <div style={{ position: "relative" }}>
                                    {
                                      video.url.length > 0 ?
                                        <video
                                          id={`selection_video_${index}`}
                                          className={"selection-form-video"}
                                          onClick={() => {
                                            handleSeeExampleClick(video.url, "video");
                                          }}
                                          src={video.url}
                                        />
                                        :
                                        <div
                                          className="dummy-logo-container"
                                          onClick={() => {
                                            handleChangeClick(true, index, true);
                                          }}
                                        >
                                          <img
                                            width={60}
                                            height={60}
                                            src="assets/dummy-video.png"
                                            alt="Logo Placeholder"
                                            className="dummy-video-image"
                                          />
                                        </div>
                                    }
                                    {(video.duration && video.duration !== 0) ? (
                                      <span className="duration-container">{getDuration(video.duration)}</span>
                                    ) : <></>}
                                  </div>
                                  <button
                                    className="form-button no-margin"
                                    onClick={() => handleChangeClick(true, index, true)}
                                  >
                                    {
                                      requestBody[selectedSceneIndex]?.sceneConfigs
                                        .filter((x) => x.isSelected)[0]
                                        .videos?.length > 0 ? "Change" : "Upload"
                                    }
                                  </button>
                                </div>
                              ))}
                          </div>

                        </div>
                      );
                    }
                    else {
                      return null;
                    }
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="error-section">{showErrorSection && <span>{errorText}</span>}</div>
          {showUpload && (
            <PickerOverlay
              apikey={"AbiIdt2ueRKuOIUvReyfaz"}
              onUploadDone={(res) => onUploadDone(res)}
              pickerOptions={{
                onClose: () => {
                  setShowUpload(false);
                  setChangeDialogOpen(false);
                },
                accept: isVideo ? ["video/*"] : ["image/*"],
                fromSources: isVideo
                  ? ["local_file_system", "url", "googledrive", "dropbox", "facebook", "instagram"]
                  : ["local_file_system", "url", "imagesearch", "googledrive", "dropbox", "facebook", "instagram"],

              }}
            // onClose={()=>{setShowUpload(false)}}
            />
          )}

          <div className="scenes-footer">
            <button className="footer-btn back-btn" onClick={handleCoachMark}>
              Help
            </button>
            <button className="footer-btn back-btn" onClick={handlePrevClick} disabled={selectedSceneIndex == 0}>
              Back
            </button>
            <button
              className="footer-btn next-btn"
              onClick={handleNextClick}
              disabled={selectedSceneIndex >= data.scenes.length - 1}
            >
              Next
            </button>
            <button className="footer-btn sf-generate-btn" onClick={goToCheckout}>
              Generate
            </button>
          </div>
          <Modal show={showVoiceOverModal} onHide={handleShowVoiceOver} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="text-wrapper" style={{ textAlign: "center", width: "100%" }}>
                <span>
                  Are you sure you want to change the voice? All the script voices will be updated after changing the
                  voice.
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="action-container">
                <button
                  className="change-save-button cancel-button"
                  onClick={() => {
                    setShowVoiceOverModal(false);
                  }}
                >
                  {" "}
                  No{" "}
                </button>
                <button className="change-save-button" onClick={updateVoiceOvers}>
                  {" "}
                  Yes{" "}
                </button>
              </div>
            </Modal.Footer>
          </Modal>


          {showCoachMark && (
            <div className="coach-mark-container">
              <div className={`coach-mark-wrapper step-${coachMarkStepIndex}`}>
                <img className="coach-mark-arrow" src="assets/coach-mark-arrow.png" alt="" />
                <div className="coach-mark-content">
                  <p>{coachMarkSteps[coachMarkStepIndex]}</p>
                  <button className="coach-mark-btn" onClick={setCoachMarkStep}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <div className="loading-container" style={{ opacity: "1", position: "fixed" }}>
          <span>Please wait ...</span>
        </div>
      )}
    </>
  );
};

export default ScenesPage;
